
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [hasModel],

    props: {
        // eslint-disable-next-line vue/require-default-prop
        to: {
            type: null,
        },
        label: {
            type: String,
            default: '',
        },
        propKey: {
            type: String,
            default: 'children',
        },
        noBigImage: Boolean,
    },

    data() {
        return {
            imageCount: 3,

            blockHeight: 'auto',
        }
    },

    computed: {
        showedImages() {
            if (this.$route.path.includes('wedding-inspiration')) return []
            return this.model[this.propKey]?.length
                ? this.model[this.propKey].filter((el, index) => {
                      return index < this.imageCount
                  })
                : []
        },

        apiUrl() {
            return process.env.apiUrl + '/api/v1/attachments'
        },

        image() {
            return this.model?.cover
                ? `${this.apiUrl}/1920/1080/${this.model.cover.id}`
                : this.showedImages?.length && this.showedImages[0].cover
                ? `${this.apiUrl}/1920/1080/${this.showedImages[0].cover.id}`
                : null
        },
    },

    mounted() {
        if (process.browser) window.addEventListener('resize', this.setHeight)
    },

    destroyed() {
        if (process.browser) window.removeEventListener('resize', this.setHeight)
    },

    methods: {
        setHeight() {
            if (process.browser) {
                const innerWidth = window.innerWidth
                if (innerWidth < 992) {
                    this.blockHeight = 'auto'
                    return
                }
                if (this.$refs.firstImageWrapper) {
                    const fullHeight = window.innerHeight
                    const imageWrapperHeight = this.$refs.firstImageWrapper.getBoundingClientRect().height
                    const imageHeight = this.$refs.firstImage.$el.getBoundingClientRect().height

                    if (imageHeight > fullHeight || imageHeight > imageWrapperHeight) {
                        this.blockHeight = '100%'
                        return
                    }

                    const imagesHeight = this.showedImages?.length ? 100 : 0
                    const headerHeight = 64
                    const breadCrumbsHeight = 46
                    const otherElements = 2 * breadCrumbsHeight + headerHeight + imagesHeight + 32
                    this.blockHeight = `${fullHeight - otherElements}px`
                }
            }
        },

        imageLoaded() {
            this.$nextTick(() => {
                if (process.browser) this.setHeight()
            })
        },

        toggleImages() {
            this.imageCount = this.imageCount === 3 ? 999 : 3
        },
    },
}
