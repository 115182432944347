
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        backgroundColor: {
            type: String,
            default: '',
        },
        buttonText: {
            type: String,
            default: '',
        },
        buttonUrl: {
            type: String,
            default: '',
        },
        bgImage: {
            type: String,
            default: '',
        },
        mobileBgImage: {
            type: String,
            default: '',
        },
    },

    computed: {
        image() {
            if (process.browser) return window.innerWidth > 576 ? this.bgImage : this.mobileBgImage || this.bgImage
            return null
        },
    },
}
