
import debounce from '@/mixins/debounce'
import { mapState } from 'vuex'
import hasPageMeta from '@/mixins/hasPageMeta'

export default {
    mixins: [hasPageMeta],

    props: {
        content: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    async fetch() {
        await this.getPhotographer(this.content.photographerSlug, 'defaultPhotographer')
    },

    data() {
        return {
            defaultPhotographer: {},
            photographer: {},
        }
    },

    computed: {
        ...mapState({
            taxonomies: (state) => state.data.taxonomies || {},
        }),

        currentTaxonomy() {
            const possibleTaxonomies = ['styles', 'settings', 'seasons', 'services']
            const keys = this.$route.query ? Object.keys(this.$route.query).filter((el) => possibleTaxonomies.includes(el)) : []
            return keys?.length ? this.taxonomies[keys[0]].find((el) => el.slug === this.$route.query[keys[0]]) || {} : {}
        },

        currentTaxonomyBanner() {
            return this.currentTaxonomy.metas?.banner_image ? JSON.parse(this.currentTaxonomy.metas.banner_image).url : this.currentTaxonomy.cover?.url
        },

        pageHeading() {
            return this.currentTaxonomy?.slug ? this.content.taxonomyPageHeading.replace('%taxonomy%', this.currentTaxonomy?.plural_name) : this.content.name
        },

        computedContent() {
            return this.currentTaxonomy?.slug ? this.currentTaxonomy : this.content
        },

        taxonomySiteTitle() {
            return this.currentTaxonomy?.slug
                ? this.currentTaxonomy.metas?.meta_title || this.content.taxonomyPageTitle.replace('%taxonomy%', this.currentTaxonomy?.plural_name)
                : null
        },

        siteTitle() {
            return `Loverly - ${this.taxonomySiteTitle || this.content.metaTitle}`
        },

        siteDescription() {
            return this.currentTaxonomy?.metas?.meta_description || this.computedContent.excerpt
        },

        cover() {
            return this.currentTaxonomyBanner || this.content.image
        },

        previewImage() {
            return this.currentTaxonomyBanner || this.content.previewImage
        },

        computedPhotographer() {
            return this.currentTaxonomy?.slug ? this.photographer || {} : this.defaultPhotographer
        },

        photographerText() {
            return this.computedPhotographer?.id ? this.content.photographerText.replace('%photographer%', this.computedPhotographer.name) : null
        },
    },

    watch: {
        currentTaxonomy: {
            handler() {
                if (this.currentTaxonomy?.metas?.photographer_credit) this.getPhotographer(this.currentTaxonomy.metas.photographer_credit, 'photographer')
                else this.photographer = {}
            },
            immediate: true,
        },
    },

    created() {
        this.search = this.$route.query.q
    },

    methods: {
        doSearch: debounce(function (search) {
            this.$router.push({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, q: search || undefined } })
        }, 500),

        async getPhotographer(slug, key) {
            const res = await this.$axios.$get(`/api/v1/content?url=/vendors/${slug}`)
            this.$set(this, key, res.data)
        },
    },
}
