
import hasModel from '~/mixins/hasModel'
import hasContentMeta from '~/mixins/hasContentMeta'
import outboundClickTrack from '~/mixins/outboundClickTrack'

export default {
    name: 'WeddingInspirationSingle',

    mixins: [hasModel, hasContentMeta, outboundClickTrack],
}
