
import { mapState } from 'vuex'
import hasPageMeta from '@/mixins/hasPageMeta'
import hasModel from '~/mixins/hasModel'
import hasContentMeta from '~/mixins/hasContentMeta'
import outboundClickTrack from '~/mixins/outboundClickTrack'

export default {
    name: 'RealWeddingSingle',

    mixins: [hasModel, hasContentMeta, hasPageMeta, outboundClickTrack],

    async fetch() {
        await this.getVendors()
        this.$store.dispatch('jsonloader/fetchJson', 'real-wedding.json')
    },

    data() {
        return {
            taxonomyVendors: [],
            showAllVendors: false,
        }
    },

    computed: {
        ...mapState({
            content: (state) => state.jsonloader.data['real-wedding.json'] || null,
        }),

        realWeddingStyle() {
            return this.styles?.length ? this.styles[0] : undefined
        },

        venue() {
            return this.venues?.length ? this.venues[0] : undefined
        },

        season() {
            return this.seasons?.length ? this.seasons[0] : undefined
        },

        photographer() {
            return this.photographers?.length ? this.photographers[0] : undefined
        },

        religion() {
            return this.religions?.length ? this.religions[0] : undefined
        },

        topLink() {
            if (this.realWeddingStyle)
                return {
                    name: 'real-weddings-styles-taxonomy',
                    label: this.realWeddingStyle.name,
                    to: { name: 'real-weddings-styles-taxonomy', params: { taxonomy: this.realWeddingStyle.slug } },
                }
            return undefined
        },

        weddingLocation() {
            return this.model?.metas?.location ? this.model?.metas?.location : this.locations?.length ? this.locations[0].name : undefined
        },

        siteTitle() {
            const titleParts = [`${this.model?.name}`, `${this.weddingLocation || ''}`]
            return 'Loverly - ' + titleParts.filter((el) => el !== '').join(' | ')
        },

        siteDescription() {
            return this.model?.excerpt ? this.model.excerpt.slice(0, 140) : this.globalMeta?.description
        },

        previewImage() {
            return this.model?.cover?.url ? this.model.cover.url : this.images?.length ? this.images[0].cover?.url : this.globalMeta?.image
        },

        listContentModel() {
            return {
                exclude_id: this.model?.id,
                type: 'list-section',
                title: this.content.relatedSectionTitle,
                per_page: 6,
                contentType: 'real-weddings',
                apiQuery: { taxonomy_styles: this.realWeddingStyle?.slug },
                paramsObject: { taxonomy: this.realWeddingStyle?.slug },
                route: 'real-weddings-styles-taxonomy',
            }
        },
    },

    watch: {
        $route: {
            handler() {
                this.$set(this, 'taxonomyVendors', [])

                this.getVendors()
            },
        },
    },

    methods: {
        async getTaxonomyVendors() {
            await this.$axios
                .$get(`/api/v1/content/list`, {
                    params: {
                        page: 1,
                        per_page: 24,
                        content_type: 'vendors',
                        'taxonomy_real-weddings': this.model.slug,
                        'order-by': 'created_at',
                        'order-dir': 'desc',
                        exclude_id: this.taxonomyVendors.map((vendor) => vendor.id),
                    },
                })
                .then((response) => {
                    this.$set(this, 'taxonomyVendors', [...this.taxonomyVendors, ...response.data])
                })
        },

        async getVendors() {
            if (this.vendors?.length)
                await this.$axios
                    .$get(`/api/v1/content/list`, {
                        params: {
                            page: 1,
                            per_page: 24,
                            content_type: 'vendors',
                            id: this.vendors.map((el) => el.id),
                            'order-by': 'created_at',
                            'order-dir': 'desc',
                        },
                    })
                    .then((res) => {
                        this.$set(this, 'taxonomyVendors', [...this.taxonomyVendors, ...res.data])
                        this.getTaxonomyVendors()
                    })
            else this.getTaxonomyVendors()
        },
    },
}
