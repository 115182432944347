import { render, staticRenderFns } from "./TwoColumnGrid.vue?vue&type=template&id=52da2ed7&lang=pug"
import script from "./TwoColumnGrid.vue?vue&type=script&lang=js"
export * from "./TwoColumnGrid.vue?vue&type=script&lang=js"
import style0 from "./TwoColumnGrid.vue?vue&type=style&index=0&id=52da2ed7&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default})
