
export default {
    props: {
        type: {
            type: String,
            default: 'vimeo',
        },

        videoUrl: {
            type: String,
            default: '',
        },

        controls: {
            type: Boolean,
            default: true,
        },

        defaultBg: {
            type: String,
            default: 'bg-black',
        },
    },

    data() {
        return {
            playing: false,
            options: {
                color: '9c4c3e',
                responsive: true,
            },
            percent: 0,
        }
    },

    methods: {
        play() {
            this.$refs.player.play()
        },

        pause() {
            this.$refs.player.pause()
        },

        videoEnd() {
            this.percent = 0
        },

        timeupdate(e) {
            this.percent = e.percent
        },
    },
}
