import { render, staticRenderFns } from "./Product.vue?vue&type=template&id=587b7e52&lang=pug"
import script from "./Product.vue?vue&type=script&lang=js"
export * from "./Product.vue?vue&type=script&lang=js"
import style0 from "./Product.vue?vue&type=style&index=0&id=587b7e52&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyColorItem: require('/app/components/elements/ColorItem.vue').default,LoverlySaveButton: require('/app/components/elements/user/SaveButton.vue').default,LoverlyListContentRenderer: require('/app/components/list/ListContentRenderer.vue').default})
