
export default {
    props: {
        number: {
            type: null,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
}
