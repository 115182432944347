
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [hasModel],

    props: {
        hideSharing: Boolean,
    },

    computed: {
        isInspiration() {
            return this.model?.content_type?.slug === 'wedding-inspiration'
        },
    },
}
