export default {
    methods: {
        scrollToTop() {
            if (process.browser) {
                const element = document.getElementById('list-result')
                if (element) document.documentElement.scrollTop = element.offsetTop - 200
            }
        },
    },
}
