
export default {
    props: {
        text: {
            type: String,
            default: '',
        },

        btntext: {
            type: String,
            default: '',
        },

        url: {
            type: String,
            default: '',
        },
    },
}
