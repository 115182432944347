import { render, staticRenderFns } from "./Renderer.vue?vue&type=template&id=48f91d32&lang=pug"
import script from "./Renderer.vue?vue&type=script&lang=js"
export * from "./Renderer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyBlockRenderer: require('/app/components/editor/BlockRenderer.vue').default})
