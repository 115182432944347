import { render, staticRenderFns } from "./LoadMoreContainer.vue?vue&type=template&id=6dcb5788&lang=pug"
import script from "./LoadMoreContainer.vue?vue&type=script&lang=js"
export * from "./LoadMoreContainer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyLoadingSpinner: require('/app/components/elements/LoadingSpinner.vue').default,LoverlyScrollObserver: require('/app/components/elements/ScrollObserver.vue').default})
