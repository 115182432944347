
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        lead: {
            type: String,
            default: '',
        },
        buttonText: {
            type: String,
            default: 'Get Started',
        },
        bgImage: {
            type: String,
            default: '',
        },
        mobileBgImage: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '#F5E6E7',
        },
        tool: {
            type: String,
            default: '',
        },

        colorOrImage: {
            type: String,
            default: 'true',
        },

        targetUrl: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            modalShow: false,
        }
    },

    computed: {
        text() {
            return this.lead ? unescape(this.lead) : null
        },

        image() {
            if (process.browser) return window.innerWidth > 576 ? this.bgImage : this.mobileBgImage

            return null
        },
    },

    methods: {
        openModal() {
            this.modalShow = true
            this.$cookies.set('tool', this.tool)
        },
    },
}
