
export default {
    props: {
        category: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },

        perPage: {
            type: null,
            default: 6,
        },

        text: {
            type: String,
            default: '',
        },

        products: {
            type: String,
            default: '',
        },

        buttonText: {
            type: String,
            default: '',
        },
    },

    async fetch() {
        this.productList = JSON.parse(unescape(this.products || [])) || []

        await this.$axios
            .$get(`/api/v1/content/list`, {
                params: {
                    taxonomy_categories: this.category || undefined,
                    content_type: 'shop',
                    per_page: parseInt(this.perPage),
                    exclude_id: this.productList.map((el) => el.id),
                },
            })
            .then((res) => {
                this.list = res.data
            })
    },

    data() {
        return {
            list: [],
            productList: [],
        }
    },

    computed: {
        computedProducts() {
            const products = [...this.productList, ...this.list]
            return products?.length > this.perPage ? products.slice(0, this.perPage) : products
        },
    },
}
