
import { mapState } from 'vuex'
import hasModel from '@/mixins/hasModel'
import hasPageMeta from '@/mixins/hasPageMeta'
import queryParams from '@/mixins/queryParams'

export default {
    name: 'ArticleListPage',

    mixins: [hasModel, queryParams, hasPageMeta],

    data() {
        return {
            content: [],
            showSearch: false,
            search: null,
        }
    },

    computed: {
        categories() {
            return this.computedModel?.children?.length ? this.computedModel.children.filter((el) => !el.is_hidden) : []
        },

        parent() {
            const parent = this.filteredContentTypes?.length
                ? this.filteredContentTypes.find((el) => {
                      return (
                          el.children.findIndex((c) => {
                              return c.slug === this.computedModel.slug
                          }) !== -1
                      )
                  })
                : {}
            return parent || {}
        },

        apiUrl() {
            return process.env.apiUrl
        },

        isMainPage() {
            return this.model?.slug === 'planning' && !this.$route.query.category
        },

        computedModel() {
            return this.$route.query.category
                ? this.filteredContentTypes?.length
                    ? this.filteredContentTypes.find((el) => el.slug === this.$route.query.category)
                    : {}
                : this.model
        },

        ...mapState({
            contentTypes: (state) => state.data.contentTypes,
        }),

        filteredContentTypes() {
            const planning = this.contentTypes.find((el) => el.slug === 'planning')
            if (planning && planning.children) return planning.children
            return []
        },

        siteTitle() {
            return 'Loverly - Wedding Planning Advice & Wedding Tips'
        },

        siteDescription() {
            return "Get all the wedding planning advice you need from Loverly's wedding planning experts!"
        },
    },
}
