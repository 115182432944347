
import download from 'js-file-download'

export default {
    props: {
        document: {
            type: String,
            default: '',
        },
        documentName: {
            type: String,
            default: '',
        },
    },

    methods: {
        download() {
            const url = `/api/v1/exclusive_files/${this.document}?download=1`

            this.$axios
                .get(url, { responseType: 'arraybuffer' })
                .then((res) => {
                    download(res.data, `${this.documentName}.pdf`)
                })
                .catch((e) => {
                    if (e.response.status === 402) {
                        this.$toast.error('You dont have permission to see this document').goAway(3000)
                    }
                })
        },
    },
}
