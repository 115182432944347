
import hasModel from '@/mixins/hasModel'
import ArticlePage from './ArticlePage'
import ArticleListPage from './ArticleListPage'
import WeddingInspiration from './WeddingInspiration'
import WeddingInspirationList from './WeddingInspirationList'
import RealWedding from './RealWedding'
import Vendor from './Vendor'
import Video from './Video'
import Product from './Product'
import DefaultPage from './DefaultPage'
export default {
    name: 'ContentPage',

    components: { DefaultPage },

    mixins: [hasModel],

    data() {
        return {
            next: null,
        }
    },

    computed: {
        isList() {
            return this.model && this.model.is_content_type
        },

        layout() {
            if (this.isList) {
                switch (this.model.slug) {
                    case 'planning':
                        return ArticleListPage

                    case 'wedding-inspiration':
                    case 'media':
                        return WeddingInspirationList

                    default:
                        return ArticleListPage
                }
            }
            if (this.model && this.model.content_type && this.model.content_type.slug) {
                if (this.model.content_type.template === 'blog') return ArticlePage
                if (this.model.content_type.template === 'album') return RealWedding
                if (this.model.content_type.template === 'inspiration') return WeddingInspiration
                if (this.model.content_type.template === 'vendor') return Vendor
                switch (this.model.content_type.slug) {
                    case 'planning':
                    case 'articles':
                    case 'posts':
                    case 'post':
                        return ArticlePage

                    case 'categories':
                    case 'category':
                        return ArticleListPage

                    case 'wedding-inspiration':
                    case 'media':
                        return WeddingInspiration

                    case 'real-weddings':
                    case 'real-wedding':
                        return RealWedding

                    case 'vendor':
                    case 'vendors':
                        return Vendor

                    case 'video':
                    case 'videos':
                        return Video

                    case 'product':
                    case 'products':
                        return Product

                    case 'shop':
                        return Product
                }
            }
            return 'default'
        },
    },

    methods: {
        async loadNext() {
            try {
                this.next = await this.$axios.$get(`/api/v1/content/next?url=/${this.model.url}`).then((res) => res.data)
                // this.$router.push({ params: { pathMatch: this.$route.params.pathMatch.replace(this.model.slug, this.next.slug) } })
            } catch (e) {}
        },
    },
}
