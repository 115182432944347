import { render, staticRenderFns } from "./MultipleProducts.vue?vue&type=template&id=f3054178&scoped=true&lang=pug"
import script from "./MultipleProducts.vue?vue&type=script&lang=js"
export * from "./MultipleProducts.vue?vue&type=script&lang=js"
import style0 from "./MultipleProducts.vue?vue&type=style&index=0&id=f3054178&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3054178",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyProductItem: require('/app/components/pages/courses/ProductItem.vue').default})
