
export default {
    inheritAttrs: false,

    props: {
        // eslint-disable-next-line vue/require-default-prop
        value: null,

        size: {
            type: Number,
            default: 1,
        },
    },
}
