
export default {
    props: {
        category: {
            type: Object,
            default: () => {},
        },
        perPage: {
            type: Number,
            default: 9,
        },
        sectionTitle: {
            type: String,
            default: '',
        },

        metaQuery: {
            type: Object,
            default: () => {
                return {}
            },
        },

        infiniteScroll: Boolean,
    },

    async fetch() {
        this.loading = true
        this.loaded = false
        await this.$axios
            .$get(`/api/v1/content/list`, {
                params: {
                    page: this.page,
                    content_type: this.category.slug,
                    per_page: this.perPage,
                    q: this.$route.query.q || undefined,
                    category: this.$route.query.category || undefined,
                    'order-by': 'publish_at',
                    'order-dir': 'desc',
                    ...this.metaQuery,
                },
            })
            .then((res) => {
                this.data = this.page === 1 ? res.data : [...this.data, ...res.data]
            })
            .catch((e) => {})
            .finally(() => {
                this.loaded = true
                this.loading = false
            })
    },

    data() {
        return {
            observer: null,
            loaded: false,
            loading: false,
            data: [],
            page: 1,
        }
    },

    watch: {
        $route() {
            this.page = 1
            this.$fetch()
        },
    },

    mounted() {
        const interVal = setInterval(() => {
            if (this.observer && this.$refs.scrollObserver) {
                this.$nextTick(() => {
                    this.observer.observe(this.$refs.scrollObserver)
                })
                clearInterval(interVal)
            }
        }, 100)
    },

    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect()
            this.observer = null
        }
    },

    created() {
        if (process.browser)
            this.observer = new IntersectionObserver(this.observed, {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                threshold: 0.75,
            })
    },

    methods: {
        observed(entries) {
            entries.forEach(({ target, isIntersecting }) => {
                if (isIntersecting && !this.loading && this.infiniteScroll) {
                    this.page++
                    this.$fetch()
                }
            })
        },
    },
}
