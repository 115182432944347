
export default {
    props: {
        images: {
            type: Array,
            default: () => {
                return []
            },
        },

        parentData: {
            type: Object,
            default: () => {
                return {}
            },
        },

        buttonLabel: {
            type: String,
            default: 'Show All Images',
        },

        imageCount: {
            type: Number,
            default: undefined,
        },
        gap: {
            type: [Number, String],
            default: '1',
        },
        transparent: Boolean,
        showName: Boolean,
        noWrap: Boolean,
        rounded: Boolean,
        defaultMaxHeight: {
            type: String,
            default: null,
        },
        defaultRatio: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            generatedImages: [],
            fewImages: [],
            orderedImages: [],
            columnStat: {
                count: 6,
                lg: 2,
                sm: 1,
            },
            showAll: false,
            defaultImageCount: 12,
            noWrapCol: {
                sm: 0,
                lg: 0,
            },
            maxHeight: this.defaultMaxHeight || 'auto',
            additionalGridClasses: '',
        }
    },

    computed: {
        apiUrl() {
            return process.env.apiUrl + '/api/v1/attachments'
        },

        isGenerated() {
            return this.images?.length === this.generatedImages?.length
        },

        colClass() {
            return this.noWrap ? `grid-cols-2 sm:grid-cols-${this.noWrapCol.sm || '6'} lg:grid-cols-${this.noWrapCol.lg || '12'}` : 'grid-cols-1'
        },

        fewImagesCount() {
            return this.fewImages?.length
        },
    },

    watch: {
        isGenerated() {
            if (this.isGenerated) {
                // if (this.generatedImages.length <= (this.defaultImageCount || 12)) this.$set(this, 'showAll', true)
                this.$set(this, 'fewImages', this.orderImages(this.generatedImages.slice(0, this.defaultImageCount)))
                this.$set(this, 'orderedImages', this.orderImages(this.generatedImages))
            }
        },

        $route: {
            handler() {
                this.$set(this, 'showAll', false)
            },
        },

        showAll: {
            handler() {
                if (this.defaultMaxHeight) this.maxHeight = this.showAll ? 'auto' : process.browser && window.innerWidth > 576 ? this.defaultMaxHeight : 'auto'
            },
            immediate: true,
        },

        fewImagesCount: {
            handler() {
                if (this.fewImagesCount < 5) {
                    switch (this.fewImagesCount) {
                        case 1:
                            this.additionalGridClasses = 'only-one-image'
                            break
                        case 2:
                            this.additionalGridClasses = 'only-two-images'
                            break
                        case 3:
                            this.additionalGridClasses = 'only-three-images'
                            break
                        case 4:
                            this.additionalGridClasses = 'only-four-images'
                            break
                    }
                }
            },
            immediate: true,
        },
    },

    mounted() {
        if (this.imageCount) {
            this.$set(this, 'defaultImageCount', this.imageCount)
        }
        if (process.browser) {
            if (window.innerWidth <= 576) this.$set(this, 'defaultImageCount', 3)
            this.generateImages()
        }
    },

    methods: {
        generateImages() {
            this.images.map((el) => {
                const imageObj = {
                    src: this.getImageSrc(el),
                    id: el.id,
                    slug: el.slug,
                    name: el.name,
                }

                const image = new Image()
                image.src = imageObj.src

                image.onload = () => {
                    imageObj.ratio = image.width / image.height

                    if (imageObj.ratio < this.defaultRatio) {
                        // imageObj.class = 'sm:col-span-1 lg:col-span-2'
                        imageObj.column = this.columnStat.sm
                        this.$set(this.noWrapCol, 'sm', this.noWrapCol.sm + 1)
                        this.$set(this.noWrapCol, 'lg', this.noWrapCol.lg + 2)
                    } else if (imageObj.ratio >= this.defaultRatio) {
                        // imageObj.class = 'sm:col-span-2 lg:col-span-2'
                        imageObj.column = this.columnStat.lg
                        this.$set(this.noWrapCol, 'sm', this.noWrapCol.sm + 2)
                        this.$set(this.noWrapCol, 'lg', this.noWrapCol.lg + 4)
                    }

                    this.generatedImages.push(imageObj)
                }
            })
        },

        getImageSrc(image) {
            return `${this.apiUrl}/1920/1080/${image.cover.id}`
        },

        orderImages(images) {
            let gridItems = []
            const restItems = []

            gridItems = images.reduce((acc, el) => {
                restItems.map((item, index) => {
                    if (this.checkImage(item, acc)) {
                        acc.push(item)
                        restItems.splice(index, 1)
                    }
                })

                if (this.checkImage(el, acc)) {
                    acc.push(el)
                } else {
                    const lasItem = [...acc].reverse().find((item) => item.column === this.columnStat.sm)
                    if (lasItem?.id) {
                        const lastItemIdx = acc.findIndex((item) => item.id === lasItem.id)

                        if (lastItemIdx !== -1) acc.splice(lastItemIdx, 1)
                        if (lasItem?.id) restItems.push(lasItem)

                        const idx = restItems.findIndex((item) =>
                            lasItem.column === this.columnStat.sm ? item.column === this.columnStat.lg : item.column === this.columnStat.sm
                        )

                        if (idx !== -1) {
                            acc.push(restItems[idx])
                            restItems.splice(idx, 1)
                        } else {
                            restItems.push(el)
                        }
                    } else {
                        restItems.push(el)
                    }
                }

                return acc
            }, [])

            if (restItems?.length) {
                if (!this.checkImage(gridItems[gridItems.length - 1], gridItems)) {
                    const lasItem = [...gridItems].reverse().find((el) => el.column === this.columnStat.sm)
                    const lastItemIdx = gridItems.findIndex((el) => el.id === lasItem.id)

                    if (lastItemIdx !== -1) gridItems.splice(lastItemIdx, 1)
                    if (lasItem?.id) restItems.push(lasItem)

                    const idx = restItems.findIndex((el) =>
                        lasItem.column === this.columnStat.sm ? el.column === this.columnStat.lg : el.column === this.columnStat.sm
                    )

                    if (idx !== -1) {
                        gridItems.push(restItems[idx])
                        restItems.splice(idx, 1)
                    }
                }
                gridItems = [...gridItems, ...this.orderImages(restItems)]
            }

            return gridItems
        },

        checkImage(image, images) {
            const sum = images.reduce(function (sum, item) {
                return sum + item.column
            }, 0)

            const modulo = sum % this.columnStat.count

            return modulo + image.column <= this.columnStat.count && this.columnStat.count - (modulo + image.column) !== this.columnStat.sm
        },

        showAllEvent() {
            this.$emit('showAll')
            this.$set(this, 'showAll', true)
            document.documentElement.scrollTop = this.$refs.imageGrid.offsetTop - 50
        },
    },
}
