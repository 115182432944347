
import Heading from '@/components/elements/typography/Heading'
import Paragraph from '@/components/elements/typography/Paragraph'
import LazyImage from '@/components/elements/Image'
import LoverlyCtaBox from '@/components/elements/CtaBox'
import LoverlyListicle from '@/components/elements/Listicle'
import LoverlyListBlock from '@/components/blocks/ListBlock'
import LoverlyLandingHero from '@/components/blocks/LandingHero'
import LoverlyCtaSection from '@/components/blocks/CtaSection'
import LoverlyTwoColumnGrid from '@/components/blocks/TwoColumnGrid'
import LoverlyCreditCard from '@/components/blocks/CreditCard'
import LoverlyBenefitBar from '@/components/blocks/BenefitBar'
import LoverlyPaidDocumentsGrid from '@/components/blocks/PaidDocumentsGrid'
import LoverlyDocumentLink from '@/components/blocks/DocumentLink'
import LoverlyHighlightedContent from '@/components/blocks/HighlightedContent'
import LoverlySingleProduct from '@/components/blocks/SingleProduct'
import LoverlySingleVendor from '@/components/blocks/SingleVendor'
import LoverlyMultipleProducts from '@/components/blocks/MultipleProducts'
import LoverlyFullWidthColorBanner from '@/components/blocks/FullWidthColorBanner'
import LoverlyRealWeddingAlbum from '@/components/blocks/RealWeddingAlbum'

const DummyComponent = {
    render(h) {
        const tagName = this.$options?._componentTag || ''
        return h('span', { class: tagName.replace(/lce-/, 'loverly-ce-') }, this.$slots.default)
    },
}

export default {
    props: {
        model: {
            type: Object,
            default: () => [],
        },
    },

    computed: {
        blockData() {
            return this.model && this.model.data ? this.model.data : {}
        },

        blockContent() {
            return this.blockData && this.blockData.text ? this.blockData.text.replaceAll(/\${[^}]*}/gm, '').replaceAll(/IMG/gm, 'img') : ''
        },

        blockType() {
            return this.model && this.model.type ? this.model.type : 'paragraph'
        },

        tableData() {
            const rows = this.blockData.content || []
            const first = rows && rows.length ? rows[0] : []
            const headings = []
            let thead = ''
            first.forEach((el) => {
                thead += `<th scope="col">${el}</th>`
                headings.push(el)
            })
            let tbody = ''
            rows.forEach((row, index) => {
                if (index === 0) return
                tbody += '<tr>'
                row.forEach((c, rowIndex) => {
                    tbody += `<td scope="row"><span class="head">${headings[rowIndex]}</span>${c}</td>`
                })
                tbody += '</tr>'
            })
            return { thead, tbody }
        },

        blockComponent() {
            let template = `<div>${this.blockContent}</div>`
            const classList = []
            if (this.blockData.alignment) classList.push(`text-${this.blockData.alignment}`)
            switch (this.blockType) {
                // Header
                case 'header':
                    template = `<heading class="${classList.join(' ')} ce-loverly-heading ce-component" :level="${this.blockData.level || 5}">${
                        this.blockContent
                    }</heading>`
                    break

                // Delimiter
                case 'delimiter':
                    template = `<hr class="ce-loverly-delimiter">`
                    break

                // List: Ordered, Unordered
                case 'list':
                    if (this.blockData.items && this.blockData.items.length) {
                        const listType = this.blockData.style === 'ordered' ? 'ol' : 'ul'
                        template = `<${listType} class="ce-loverly-list ce-component">`
                        this.blockData.items.forEach((el) => {
                            template += `<li>${el}</li>`
                        })
                        template += `</${listType}>`
                    }
                    break

                // Quote
                case 'quote':
                    template = `<blockquote class="ce-loverly-quote ce-component"> <div class="stylistic-quote-mark" aria-hidden="true">&ldquo;</div> <p>${this.blockContent}</p>`
                    if (this.blockData.caption) template += `<cite>${this.blockData.caption}</cite>`
                    template += `<div class="stylistic-quote-mark-right" aria-hidden="true">&rdquo;</div>`
                    template += `</blockquote>`
                    break

                // Code
                case 'code':
                    template = `<pre class="ce-loverly-code ce-component"><code>${this.blockData.code}</code></pre>`
                    break

                // Image
                case 'image':
                case 'BlockImage':
                    if (this.blockData.withBorder) classList.push('with-border')
                    if (this.blockData.withBackground) classList.push('with-bg')
                    if (this.blockData.stretched) classList.push('stretched')
                    template = `<div class="ce-loverly-image ce-component"><client-only><figure>${
                        this.blockData.link
                            ? '<a target="_blank" href="' + this.blockData.link + '" title="' + this.stripHtml(this.blockData.caption || 'Image') + '">'
                            : ''
                    }<lazy-image src="${this.blockData.url}" alt="${this.stripHtml(this.blockData.caption || 'Image')}">`

                    if (this.blockData.caption && this.blockData.caption !== 'null')
                        template += `<div class="text-center">${
                            this.blockData.caption && this.blockData.caption !== 'null' ? this.blockData.caption : ''
                        }</div>`
                    template += `</lazy-image>${this.blockData.link ? '</a>' : ''}`

                    template += `</figure></client-only></div>`
                    break

                // Table
                case 'table':
                    template = `<div class="table-responsive ce-loverly-table ce-component">
                        <table class="cdx-table table table-striped table-bordered table-hover table-sm">
                                <thead ><tr>${this.tableData.thead}</tr></thead>
                                <tbody>${this.tableData.tbody}</tbody>
                        </table>
                        </div>`
                    break

                // Raw HTML
                case 'raw':
                    template = `<div class="ce-loverly-raw ce-component">${this.cleanUpHtml(this.blockData.html)}</div>`
                    break

                // Ctabox
                case 'ctabox':
                    template = `<div class="ce-loverly-ctabox ce-component"><loverly-cta-box class="btn-primary" url="${this.blockData.url}" btntext="${this.blockData.title}" text="${this.blockData.text}"></loverly-cta-box></div>`
                    break

                // Video embed
                case 'videoEmbed':
                    template = `<loverly-video-player @loaded="$emit('loaded')" videoUrl="${this.blockData.url}" type="vimeo" :controls="true"></loverly-video-player>`
                    break

                // Audio
                case 'audioEmbed':
                    template = `<loverly-audio-player file-id="${this.blockData.id}" title="${this.blockData.title || ''}" :color="'${
                        this.$twcfg.theme.colors.dustyrose['900']
                    }'"></loverly-audio-player>`
                    break

                // Listicle
                case 'listicle':
                    template = `<div class="ce-loverly-listicle ce-component"><loverly-listicle number="${this.blockData.number}" title="${this.stripHtml(
                        this.blockData.title
                    )}"></loverly-listicle></div>`
                    break

                // Embed
                case 'embed':
                    template = `<div class="embed-container ce-loverly-embed ce-component">
                        <iframe class="embed" src="${this.blockData.embed}" height="${this.blockData.height}" width="${this.blockData.width}" scrolling="no" frameborder="no" allowtransparency="true" allowfullscreen="true" style="max-width: 100;"></iframe>`
                    if (this.blockData.caption && this.blockData.caption !== 'null')
                        template += `<figcaption>${this.blockData.caption && this.blockData.caption !== 'null' ? this.blockData.caption : ''}</figcaption>`
                    template += `</div>`
                    break

                case 'grid':
                    template = `<div class='ce-loverly-grid grid ce-component gap-8 mb-10 grid-cols-1 lg:grid-cols-${(this.blockData.blocks || []).length}'>`
                    ;(this.blockData.blocks || []).forEach((el) => (template += `<div class="grid-child">${el}</div>`))
                    template += `</div>`
                    break

                case 'banner':
                    template = `<div class="ce-loverly-banner ce-component">
                        <div class="inner">
                            <h1>${this.blockData.title}</h1>
                            <p>${this.blockData.text}</p>
                            <a class="btn btn-primary" href="${this.blockData.url}">
                                ${this.blockData.urltext}
                            </a>
                        </div>
                    </div>`
                    break

                case 'contentList':
                    template = `<loverly-inline-list-block
                        class="ce-loverly-content-list ce-component"
                        block-data="${escape(JSON.stringify(this.blockData))}"
                        ></loverly-inline-list-block>`
                    break

                case 'landingHero':
                    template = `<loverly-landing-hero
                        class="ce-component ce-loverly-landing-hero"
                        title="${this.blockData.title || ''}"
                        labelText="${this.blockData.labelText || ''}"
                        guestLead="${escape(this.blockData.guestLead || '')}"
                        userLead="${escape(this.blockData.userLead || '')}"
                        guestButtonText="${this.blockData.guestButtonText || ''}"
                        userButtonText="${this.blockData.userButtonText || ''}"
                        bgImage="${this.blockData.bgImage || ''}"
                        mobileBgImage="${this.blockData.mobileBgImage || ''}"
                        tool="${this.blockData.tool || ''}"
                        containerClass="${this.blockData.container || ''}"
                        ></loverly-landing-hero>`
                    break

                case 'ctaSection':
                    template = `<loverly-full-width-color-banner
                        class="ce-component"
                        title="${this.blockData.title || ''}"
                        text="${this.blockData.lead || ''}"
                        backgroundColor="${this.blockData.color || ''}"
                        buttonText="${this.blockData.buttonText || ''}"
                        buttonUrl="${this.blockData.targetUrl || ''}"
                        bgImage="${this.blockData.bgImage || ''}"
                        mobileBgImage="${this.blockData.mobileBgImage || ''}"
                        ></loverly-full-width-color-banner>`
                    // template = `<loverly-cta-section
                    //     class="ce-component ce-loverly-cta-section"
                    //     title="${this.blockData.title || ''}"
                    //     color="${this.blockData.color || ''}"
                    //     buttonText="${this.blockData.buttonText || ''}"
                    //     lead="${escape(this.blockData.lead || '')}"
                    //     bgImage="${this.blockData.bgImage || ''}"
                    //     mobileBgImage="${this.blockData.mobileBgImage || ''}"
                    //     tool="${this.blockData.tool || ''}"
                    //     targetUrl="${this.blockData.targetUrl || ''}"
                    //     colorOrImage="${this.blockData.colorOrImage || ''}"
                    //     ></loverly-cta-section>`
                    break

                case 'twoColumnGrid':
                    template = `<loverly-two-column-grid
                        class="ce-component ce-loverly-two-column-grid"
                        text="${escape(this.blockData.text || '')}"
                        text-right="${escape(this.blockData.text_right || '')}"
                        title="${this.blockData.title || ''}"
                        title-right="${this.blockData.title_right || ''}"
                        buttonText="${this.blockData.buttonText || ''}"
                        buttonUrl="${this.blockData.buttonUrl || ''}"
                        image="${this.blockData.image || ''}"
                        image-left="${this.blockData.image_left || ''}"
                        side="${this.blockData.side || ''}"
                        side-right="${this.blockData.side_right || ''}"
                        ></loverly-two-column-grid>`
                    break

                case 'benefitBar':
                    template = `<loverly-benefit-bar
                        class="ce-component ce-loverly-benefit-bar"
                        blocks="${escape(JSON.stringify(this.blockData.blocks))}"
                        ></loverly-benefit-bar>`
                    break

                case 'creditCard':
                    template = `<loverly-credit-card
                        class="ce-component"
                        buttonLabel="${this.blockData.buttonLabel || ''}"
                        fineCopy="${this.blockData.text || ''}"
                        backgroundColor="${this.blockData.backgroundColor || ''}"
                        product="${this.blockData.product}"
                        successButtonLabel="${this.blockData.successButtonLabel || ''}"
                        successButtonUrl="${this.blockData.successButtonUrl || ''}"
                        sms="${this.blockData.isSms || ''}"
                        ></loverly-credit-card>`
                    break

                case 'paidDocuments':
                    template = `<loverly-paid-documents-grid
                        class="ce-component"
                        blocks="${escape(JSON.stringify(this.blockData.blocks))}"
                        ></loverly-paid-documents-grid>`
                    break

                case 'documentLink':
                    template = `<loverly-document-link
                        class="ce-component"
                        document="${this.blockData.id}"
                        documentName="${this.blockData.slug}"
                        >${this.$slots.default}</loverly-document-link>`
                    break

                // HighlightedContent
                case 'highlightedContent':
                    template = `<loverly-highlighted-content
                        class="ce-component"
                        title="${this.blockData.title || ''}"
                        text="${this.blockData.text || ''}"
                        ></loverly-highlighted-content>`
                    break

                // Single Vendor
                case 'singleVendor':
                    template = `<loverly-single-vendor
                        class="ce-component"
                        vendor="${escape(JSON.stringify(this.blockData.product || {}))}"
                        right="${this.blockData.right || ''}"
                        separator="${this.blockData.separator || ''}"
                        buttonText="${this.blockData.buttonText || ''}"
                        ></loverly-single-vendor>`
                    break

                // Single Product
                case 'singleProduct':
                    template = `<loverly-single-product
                        class="ce-component"
                        product="${escape(JSON.stringify(this.blockData.product || {}))}"
                        right="${this.blockData.right || ''}"
                        separator="${this.blockData.separator || ''}"
                        buttonText="${this.blockData.buttonText || ''}"
                        ></loverly-single-product>`
                    break

                // Multiple Products
                case 'multipleProducts':
                    template = `<loverly-multiple-products
                        class="ce-component"
                        products="${escape(JSON.stringify(this.blockData.productList || []))}"
                        category="${this.blockData.category || ''}"
                        title="${this.blockData.title || ''}"
                        text="${this.blockData.text || ''}"
                        perPage="${this.blockData.perPage || ''}"
                        buttonText="${this.blockData.buttonText || ''}"
                        ></loverly-multiple-products>`
                    break

                // Multiple Products
                case 'fullWidthColorBanner':
                    template = `<loverly-full-width-color-banner
                        class="ce-component"
                        title="${this.blockData.title || ''}"
                        text="${this.blockData.text || ''}"
                        backgroundColor="${this.blockData.backgroundColor || ''}"
                        buttonText="${this.blockData.buttonText || ''}"
                        buttonUrl="${this.blockData.buttonUrl || ''}"
                        bgImage="${this.blockData.bgImage || ''}"
                        mobileBgImage="${this.blockData.mobileBgImage || ''}"
                        ></loverly-full-width-color-banner>`
                    break

                // Real Wedding Album
                case 'realWeddingAlbum':
                    template = `<loverly-real-wedding-album
                        class="ce-component"
                        realWedding="${escape(JSON.stringify(this.blockData.realWedding || {}))}"
                        buttonText="${this.blockData.buttonText || 'View Full Gallery'}"
                        ></loverly-real-wedding-album>`
                    break

                // Paragraph
                default:
                    template = `<paragraph class="${classList.join(' ')} ce-loverly-text ce-component">${this.cleanUpHtml(
                        this.externalLinksNewTab(this.blockContent)
                    )}</paragraph>`
            }
            return {
                ignoredElements: [/^lce-/],
                components: {
                    Paragraph,
                    Heading,
                    LazyImage,
                    LoverlyCtaBox,
                    LoverlyListicle,
                    LoverlyListBlock,
                    LoverlyLandingHero,
                    LoverlyCtaSection,
                    LoverlyTwoColumnGrid,
                    LoverlyCreditCard,
                    LoverlyBenefitBar,
                    LceGray: DummyComponent,
                    LceBookmania: DummyComponent,
                    LceTextxs: DummyComponent,
                    LceTextmd: DummyComponent,
                    LceTextlg: DummyComponent,
                    LceTextxl: DummyComponent,
                    LceTexthighlight: DummyComponent,
                    LceDocument: LoverlyDocumentLink,
                    LoverlyPaidDocumentsGrid,
                    LoverlyHighlightedContent,
                    LoverlySingleProduct,
                    LoverlySingleVendor,
                    LoverlyMultipleProducts,
                    LoverlyFullWidthColorBanner,
                    LoverlyRealWeddingAlbum,
                },
                template,
            }
        },
    },

    methods: {
        cleanUpHtml(string) {
            let output = string

            if (string && (typeof string === 'string' || string instanceof String)) {
                // remove script tags
                output = string.replace(/<script/g, '<client-only><component is="script" ').replace(/<\/script>/g, '</component></client-only>')

                // remove ids
                output = output.replace(/\s(id)=\"[^"]+\"/g, '')

                return output
            }

            return output
        },

        externalLinksNewTab(string) {
            if (string && (typeof string === 'string' || string instanceof String)) {
                if (!string.includes('_blank')) return string.replace(/<a href/g, '<a target="_blank" href')
            }
            return string
        },

        stripHtml(string) {
            if (process.browser) {
                const doc = new DOMParser().parseFromString(string, 'text/html')
                return doc.body.textContent || ''
            } else {
                return string ? string.replace(/<[^>]*>?/gm, '') : ''
            }
        },

        getTableData() {},
    },
}
