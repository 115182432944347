
export default {
    props: {
        model: {
            type: Object,
            default: () => {},
        },

        hideMargin: Boolean,
    },

    computed: {
        blocks() {
            return this.model && this.model.blocks ? this.model.blocks : []
        },
    },
}
