import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=0505fde2&scoped=true&lang=pug"
import script from "./ErrorPage.vue?vue&type=script&lang=js"
export * from "./ErrorPage.vue?vue&type=script&lang=js"
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=0505fde2&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0505fde2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default})
