
export default {
    props: {
        // eslint-disable-next-line vue/require-default-prop
        value: null,

        autosize: Boolean,
        minHeight: {
            type: [Number],
            default: null,
        },

        maxHeight: {
            type: [Number],
            default: null,
        },

        offsetHeight: {
            type: [Number],
            default: null,
        },

        render: Boolean,

        placeholder: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            maxHeightScroll: false,
            height: 'auto',
        }
    },

    computed: {
        computedStyles() {
            if (!this.autosize) return {}
            return {
                resize: !this.isResizeImportant ? 'none' : 'none !important',
                height: this.height,
                overflow: this.maxHeightScroll ? 'auto' : !this.isOverflowImportant ? 'hidden' : 'hidden !important',
            }
        },

        isResizeImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('resize'))
        },

        isOverflowImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('overflow'))
        },

        isHeightImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('height'))
        },

        renderedValue() {
            return this.render && this.value ? this.value.replaceAll('</br>', '\n').replaceAll('<br>', '\n') : this.value
        },

        renderedPlaceholder() {
            return this.render ? this.placeholder.replaceAll('</br>', '\n').replaceAll('<br>', '\n') : this.placeholder
        },
    },

    watch: {
        value(val) {
            this.$nextTick(this.resize)
        },
        minHeight() {
            this.$nextTick(this.resize)
        },
        maxHeight() {
            this.$nextTick(this.resize)
        },
        autosize(val) {
            if (val) this.resize()
        },
    },
    mounted() {
        this.resize()
    },

    methods: {
        resize() {
            const important = this.isHeightImportant ? 'important' : ''
            this.height = `auto${important ? ' !important' : ''}`
            this.$nextTick(() => {
                let contentHeight = this.$el.scrollHeight + 1
                if (this.minHeight) contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight
                if (this.maxHeight)
                    if (contentHeight > this.maxHeight) {
                        contentHeight = this.maxHeight
                        this.maxHeightScroll = true
                    } else this.maxHeightScroll = false
                if (this.offsetHeight) contentHeight -= this.offsetHeight
                const heightVal = contentHeight + 'px'
                this.height = `${heightVal}${important ? ' !important' : ''}`
            })
            return this
        },

        handleInput(e) {
            this.$emit('input', this.render ? e.target.value.replaceAll('\n', '</br>') : e.target.value)
        },
    },
}
