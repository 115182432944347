import { render, staticRenderFns } from "./TitleBlock.vue?vue&type=template&id=23acb85d&lang=pug"
import script from "./TitleBlock.vue?vue&type=script&lang=js"
export * from "./TitleBlock.vue?vue&type=script&lang=js"
import style0 from "./TitleBlock.vue?vue&type=style&index=0&id=23acb85d&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlySaveButton: require('/app/components/elements/user/SaveButton.vue').default,LoverlyShare: require('/app/components/elements/Share.vue').default})
