
export default {
    props: {
        blocks: {
            type: String,
            default: '',
        },
    },

    computed: {
        items() {
            return this.blocks ? JSON.parse(unescape(this.blocks)) : []
        },

        gridItemsClass() {
            const cols = { 1: 'lg:grid-cols-1', 2: 'lg:grid-cols-2', 3: 'lg:grid-cols-3', 4: 'lg:grid-cols-4', 5: 'lg:grid-cols-5', 6: 'lg:grid-cols-6' }
            return this.items?.length ? cols[this.items.length] : cols['1']
        },
    },
}
