
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
    props: {
        isPosse: Boolean,
    },

    data() {
        return {
            visible: false,
            model: {
                name: '',
                excerpt: '',
                is_private: false,
            },
            events: [],
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
            item: (state) => state.board.item || {},
            posseUser: (state) => state.user?.posse?.user || {},
        }),

        ...mapGetters({
            eventIds: 'user/moodBoardsEventIds',
            posseEvents: 'user/posseEvents',
        }),

        bgImage() {
            let url = ''
            if (this.item && this.item.cover && this.item.cover.url) url = this.item.cover.url
            else url = this.model?.items?.length ? this.model.items[0].cover.url : '/icon.png'

            return `background-image: url(${url})`
        },

        items() {
            return this.model?.items?.length
                ? this.model.items.slice(this.model.items.length >= 4 ? -4 : -this.model.items.length)
                : this.item?.slug
                ? [this.item]
                : []
        },
    },

    watch: {
        visible() {
            if (this.visible && (!this.events || !this.events.length)) {
                if (this.isPosse) {
                    this.$axios
                        .$get(`/api/v1/user-posses/user/${this.posseUser.id}/guest_wedding_events/get-all`, {
                            params: {
                                ids: this.eventIds,
                            },
                        })
                        .then((res) => (this.events = res?.data))
                } else {
                    this.$axios.$get(`/api/v1/guest_wedding_events/all`).then((res) => (this.events = res?.data))
                }
            }
        },
    },

    mounted() {
        this.$store.subscribe((m) => {
            if (m && m.type === 'board/triggerBoardModal') {
                if (this.$auth.loggedIn) {
                    this.visible = true
                    this.model = JSON.parse(JSON.stringify(m.payload))
                }
            }

            if (m && m.type === 'confirmation/accept') {
                if (m.payload?.action === 'deleteBoard') {
                    if (this.isPosse) {
                        this.$axios.$delete(`/api/v1/user-posses/user/${this.posseUser.id}/boards/${this.model.slug}`).then(() => {
                            this.$emit('fetch')
                            this.$emit('deleted')
                            this.setConfirmationData({})
                            this.clear()
                        })
                    } else {
                        this.$store.dispatch(`board/deleteBoard`, this.model).finally(() => {
                            this.$emit('fetch')
                            this.$emit('deleted')
                            this.setConfirmationData({})
                            this.clear()
                        })
                    }
                }
            }
        })
    },

    methods: {
        ...mapMutations({
            boardListFetch: 'board/boardListFetch',
            boardsFetch: 'board/boardsFetch',
            setConfirmationData: 'confirmation/setConfirmationData',
        }),

        create() {
            if (!this.model.is_private) this.model.is_private = false
            if (this.isPosse) {
                if (!this.model.slug) {
                    this.$axios.$post(`/api/v1/user-posses/user/${this.posseUser.id}/boards`, { ...this.model, model: this.item }).then(() => {
                        this.$emit('fetch')
                        this.clear()
                    })
                } else {
                    this.$axios
                        .$put(`/api/v1/user-posses/user/${this.posseUser.id}/boards/${this.model.slug}`, { ...this.model, model: this.item })
                        .then(() => {
                            this.$emit('fetch')
                            this.clear()
                        })
                }
            } else {
                const action = !this.model.slug ? 'createBoard' : 'editBoard'

                this.$store.dispatch(`board/${action}`, this.model).finally(() => {
                    this.$emit('fetch')
                    this.clear()
                })
            }
        },

        toUpperCase(s) {
            return s.charAt(0).toUpperCase() + s.slice(1)
        },

        deleteBoard() {
            this.setConfirmationData({
                title: 'Delete board?',
                text: 'You won’t be able to get it back',
                acceptButtonLabel: 'Delete Board',
                action: 'deleteBoard',
            })
        },

        clear() {
            this.visible = false
            this.model.name = null
            this.model.is_private = false
            this.model.excerpt = null
        },
    },
}
