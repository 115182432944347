
import hasModel from '@/mixins/hasModel'
import toMoney from '@/mixins/toMoney'
import impression from '@/mixins/impression'
import outboundClickTrack from '@/mixins/outboundClickTrack'

export default {
    mixins: [hasModel, toMoney, impression, outboundClickTrack],

    props: {
        imageHeight: {
            type: Number,
            default: 266,
        },
        buttonText: {
            type: String,
            default: '',
        },
    },

    computed: {
        price() {
            return this.model?.metas?.price
        },

        brand() {
            return this.model?.metas?.brand
        },

        link() {
            return this.model?.metas?.affiliate_link
        },
    },
}
