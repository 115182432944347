
import ErrorPage from '@/components/pageComponents/ErrorPage'
import ContentPage from '@/components/pageComponents/ContentPage'
import hasPageMeta from '@/mixins/hasPageMeta'

export default {
    name: 'Content',

    mixins: [hasPageMeta],

    async fetch() {
        const url = this.$route.path
        this.errorCode = null
        await this.$axios
            .$get(`/api/v1/content?url=${url}`)
            .then((res) => {
                if (res.redirect) this.$nuxt.context.redirect(res.code || 301, res.redirect)
                this.content = res.data
            })
            .catch((e) => {
                this.errorMsg = e && e.response && e.response.statusText ? e.response.statusText : null
                this.errorCode = e.response.status
            })
        if (this.errorCode && process.server) {
            this.$nuxt.context.res.statusCode = this.errorCode
        }
    },

    data() {
        return {
            content: {},
            errorMsg: null,
            errorCode: null,
        }
    },

    computed: {
        siteTitle() {
            return `Loverly - ${this.content && this.content.name ? this.content.name : undefined}`
        },

        siteDescription() {
            return this.content && this.content.excerpt ? this.content.excerpt : this.errorCode ? this.errorMsg : undefined
        },

        pageComponent() {
            return this.errorCode ? ErrorPage : ContentPage
        },

        previewImage() {
            return this.content && this.content.cover && this.content.cover.url ? this.content.cover.url : `${process.env.baseUrl}/icon.png`
        },
    },

    watch: {
        '$fetchState.pending'(val) {
            if (this.$nuxt.$loading)
                this.$nextTick(() => {
                    if (val) this.$nuxt.$loading.start()
                    else this.$nuxt.$loading.finish()
                })
        },
    },
}
