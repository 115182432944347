
import list from '@/mixins/list'
import thousandsFilter from '@/mixins/thousandsFilter'
import { mapState } from 'vuex'

export default {
    mixins: [list, thousandsFilter],

    data() {
        return {
            listFilters: [],
            filterModal: false,
            orderParams: {
                'order-dir': 'asc',
                'order-by': 'slug',
            },
        }
    },

    computed: {
        ...mapState({
            content: (state) => state.jsonloader.data['wedding-inspirations.json'] || null,
        }),

        hasQuery() {
            const query = { ...this.$route.query }
            const queryKeys = query
                ? Object.keys(query).filter((el) => {
                      return query[el] && query[el].length
                  })
                : []
            return queryKeys?.length
        },

        perPage() {
            return 12
        },

        countOfInspirations() {
            return this.meta?.total ? this.content.countOfInspirations.replace('%count%', this.$options.filters.thousands(this.meta.total)) : 0
        },

        mappedList() {
            return this.list.map((el) => {
                return {
                    src: el.cover.url,
                    id: el.id,
                    slug: el.slug,
                    name: el.name,
                }
            })
        },
    },

    created() {
        this.$store.dispatch('jsonloader/fetchJson', 'wedding-inspirations.json').then(() => {
            this.$set(this, 'listFilters', this.content?.filters?.length ? JSON.parse(JSON.stringify(this.content.filters)) : [])
        })
    },

    methods: {
        reset() {
            this.filters = {}
            this.$router.push({})
        },
    },
}
