
import { mapState } from 'vuex'
import { parseISO, format } from 'date-fns'
import hasModel from '@/mixins/hasModel'

export default {
    name: 'ArticlePage',

    mixins: [hasModel],

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        readingTime() {
            return this.model && this.model.metas && this.model.metas.reading_time_seconds
                ? Math.floor(this.model.metas.reading_time_seconds / 60).toFixed(0)
                : undefined
        },

        articleDate() {
            return format(parseISO(this.model.publish_at ? this.model.publish_at : this.model.created_at), 'dd MMM yyyy')
        },

        taxonomy() {
            return this.model.taxonomy || []
        },

        tags() {
            return this.model.tags || []
        },

        categories() {
            return this.taxonomy.filter((el) => !el.is_hidden && el.content_type && el.content_type.slug === 'categories')
        },

        cover() {
            return this.model.cover && this.model.cover.id ? `${process.env.apiUrl}/api/v1/attachments/${this.model.cover.id}` : null
        },

        metas() {
            return this.model.metas || {}
        },

        hasBannerImage() {
            return this.metas?.hero_image
        },

        bannerImage() {
            return process.browser
                ? this.metas.hero_image
                    ? JSON.parse(window.innerWidth > 576 ? this.metas.hero_image : this.metas.hero_image_mobile)
                    : {}
                : {}
        },

        isAffiliate() {
            return this.metas?.is_affiliate
        },

        altText() {
            return this.metas.hero_image_alt || this.model.name
        },

        heroCatpion() {
            return this.metas.hero_caption || undefined
        },

        contentType() {
            return this.model?.content_type
        },

        isInspiration() {
            return this.model?.content_type?.slug === 'wedding-inspiration'
        },

        getFullPath() {
            const parts = this.contentType?.slug ? this.getPathPart(this.contentType) : []
            return '/' + parts.join('/')
        },

        relatedParams() {
            return {
                taxonomy_styles: this.styles?.length ? this.styles[0].slug : null,
            }
        },
    },

    methods: {
        getPathPart(type) {
            let parts = [type.slug]
            if (type && type.parent) parts = [...this.getPathPart(type.parent), ...parts]
            return parts
        },
    },
}
