
export default {
    props: {
        text: {
            type: String,
            default: '',
        },

        textRight: {
            type: String,
            default: '',
        },

        side: {
            type: null,
            default: null,
        },

        sideRight: {
            type: null,
            default: null,
        },

        imageLeft: {
            type: String,
            default: '',
        },

        image: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        titleRight: {
            type: String,
            default: '',
        },

        buttonText: {
            type: String,
            default: '',
        },

        buttonUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        lead() {
            return this.text ? unescape(this.text) : null
        },

        leadRight() {
            return this.textRight ? unescape(this.textRight) : null
        },

        isRightText() {
            return this.leadRight && this.leadRight !== '' && this.leadRight !== '<br>'
        },

        isLeftText() {
            return this.lead && this.lead !== '' && this.lead !== '<br>'
        },
    },
}
