
export default {
    props: {
        listFilters: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
}
