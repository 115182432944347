import { render, staticRenderFns } from "./ImageGrid.vue?vue&type=template&id=57c96a84&scoped=true&lang=pug"
import script from "./ImageGrid.vue?vue&type=script&lang=js"
export * from "./ImageGrid.vue?vue&type=script&lang=js"
import style0 from "./ImageGrid.vue?vue&type=style&index=0&id=57c96a84&prod&lang=postcss"
import style1 from "./ImageGrid.vue?vue&type=style&index=1&id=57c96a84&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c96a84",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyImageGridItem: require('/app/components/single/ImageGridItem.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyLoadingSpinner: require('/app/components/elements/LoadingSpinner.vue').default})
