import { mapState } from 'vuex'

export default {
    props: {
        exceptions: {
            type: null,
            default: null,
        },
    },

    fetch() {
        this.$store.dispatch('jsonloader/fetchJson', 'idc-promotions.json')
    },

    computed: {
        ...mapState({
            idcPromotions: (state) => state.jsonloader.data['idc-promotions.json'] || null,
        }),

        model() {
            if (this.idcPromotions?.items?.length) {
                if (this.promotionKey && this.idcPromotions[this.promotionKey] !== 'random')
                    return this.computedPromotions.find((el) => el.id === this.idcPromotions[this.promotionKey])
                else return this.getRandomPromotion()
            }
            return {}
        },

        computedPromotions() {
            const exceptions = this.exceptions ? this.exceptions.trim().split(',') || [] : []
            return this.idcPromotions?.items?.filter((el) => !exceptions.includes(JSON.stringify(el.id))) || []
        },
    },

    methods: {
        getRandomPromotion() {
            const id = Math.floor(Math.random() * this.computedPromotions.length)
            return this.computedPromotions[id]
        },
    },
}
