import queryParams from '~/mixins/queryParams'
import hasModel from '~/mixins/hasModel'
export default {
    mixins: [queryParams, hasModel],

    async fetch() {
        if (!this.append) this.$set(this, 'list', [])
        if (this.$route.query.page) this.pageNumber = this.$route.query.page
        await this.$axios
            .$get(
                `${this.queryParams(this.listApi, {
                    ...this.orderParams,
                    taxonomy_styles: this.decodeQueryParam('styles'),
                    taxonomy_settings: this.decodeQueryParam('settings'),
                    taxonomy_colors: this.decodeQueryParam('colors'),
                    taxonomy_seasons: this.decodeQueryParam('seasons'),
                    taxonomy_locations: this.decodeQueryParam('locations'),
                    taxonomy_services: this.decodeQueryParam('services'),
                    taxonomy_moments: this.decodeQueryParam('moments'),
                    taxonomy_categories: this.decodeQueryParam('categories'),
                    content_type: this.contentType,
                    page: this.currentPage,
                    per_page: this.perPage,
                    sort: this.$route.query.sort || undefined,
                    search: this.$route.query.q ? this.$route.query.q : this.filters.search ? this.filters.search : undefined,
                    tags: this.$route.query.tags || undefined,
                    is_randomized: this.isRandomized || undefined,
                    user_id: this.userId || undefined,
                    status: this.status || undefined,
                    ...(this.customParams || {}),
                })}`
            )
            .then((res) => {
                this.$set(this, 'list', this.append ? this.list.concat(res.data || []) : res.data || [])
                this.meta = res.meta || {}
                this.$forceUpdate()
            })
            .catch(() => {})
    },

    watch: {
        $route(val, oldVal) {
            if (!val.query.page) this.pageNumber = 1
            this.append = false
            this.$fetch()
        },
    },

    methods: {
        decodeQueryParam(key) {
            const val = this.$route.query[key] || undefined
            if (val && val.includes('/')) {
                const parts = val.split('/')
                if (parts && parts.length > 1) return parts[0]
            }
            return val
        },
    },

    computed: {
        contentType() {
            return this.$route.path
                .split('/')
                .filter((el) => el && el !== '')
                .shift()
        },

        currentPage: {
            get() {
                return this.pageNumber
            },

            set(val) {
                this.pageNumber = val
                this.append = true
                this.$fetch()
            },
        },

        perPage() {
            return 24
        },

        gridKey() {
            const filters = { ...this.filters }
            this.$delete(filters, 'page')
            return JSON.stringify(filters)
        },

        customParams() {
            return {}
        },
    },

    data() {
        return {
            isRandomized: false,
            isFilterOpen: false,
            list: [],
            filters: {},
            sort: {},
            showWelcomeCard: true,
            meta: {},
            append: true,
            pageNumber: 1,
            listFilters: [],
            listApi: '/api/v1/content/list',
            userId: null,
            status: null,
            orderParams: {},
        }
    },
}
