import { render, staticRenderFns } from "./WeddingInspiration.vue?vue&type=template&id=0f7ab662&lang=pug"
import script from "./WeddingInspiration.vue?vue&type=script&lang=js"
export * from "./WeddingInspiration.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyTitleBlock: require('/app/components/elements/single/TitleBlock.vue').default,LoverlyImageBlock: require('/app/components/elements/single/ImageBlock.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyRenderer: require('/app/components/editor/Renderer.vue').default,LoverlySingleInfo: require('/app/components/elements/single/SingleInfo.vue').default,LoverlyColorItem: require('/app/components/elements/ColorItem.vue').default,LoverlyCardTag: require('/app/components/elements/containers/card/CardTag.vue').default,LoverlySaveButton: require('/app/components/elements/user/SaveButton.vue').default,LoverlyShare: require('/app/components/elements/Share.vue').default})
