
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [hasModel],

    props: {
        isCard: Boolean,

        promotionKey: {
            type: String,
            default: '',
        },
    },

    computed: {
        clickEvent() {
            return this.model.clickEvent
                ? {
                      method: 'Google',
                      event_category: this.model.clickEvent.eventCategory,
                      event_action: this.model.clickEvent.eventAction,
                      event_albel: this.model.clickEvent.eventLabel,
                      value: this.model.clickEvent.eventValue,
                  }
                : {}
        },
    },

    methods: {
        clickHandler() {
            if (this.$auth.loggedIn) this.$store.dispatch('klaviyo/event', 'idc_intent')
        },
    },
}
