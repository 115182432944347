
import hasModel from '@/mixins/hasModel'
import { mapState } from 'vuex'
import scrollToListTop from '@/mixins/scrollToListTop'

export default {
    mixins: [hasModel, scrollToListTop],

    props: {
        modal: Boolean,
    },

    data() {
        return {
            queryObject: {},
            colorPaletteHeight: 'auto',
        }
    },

    computed: {
        ...mapState({
            taxonomies: (state) => state.data.taxonomies || [],
        }),

        items() {
            return this.taxonomies[this.model.key]
        },

        currentColors() {
            return this.queryObject[this.model.key]
                ? this.flatColors.filter((el) => {
                      return Array.isArray(this.queryObject[this.model.key])
                          ? this.queryObject[this.model.key].includes(el.slug)
                          : this.queryObject[this.model.key] === el.slug
                  })
                : []
        },

        flatColors() {
            return this.model.key === 'colors'
                ? this.items.reduce((acc, el) => {
                      acc.push(el)
                      if (el.children) el.children.forEach((x) => acc.push(x))
                      return acc
                  }, [])
                : []
        },
    },

    watch: {
        $route: {
            handler() {
                this.queryObject = JSON.parse(JSON.stringify({ ...this.$route.query }))
            },
            immediate: true,
        },
    },

    methods: {
        getLink(item) {
            const routeObject = { query: { ...this.queryObject } }
            routeObject.query[this.model.key] = this.isActive(item) ? undefined : item.slug
            return routeObject
        },

        isActive(item) {
            return this.queryObject[this.model.key]
                ? Array.isArray(this.queryObject[this.model.key])
                    ? this.queryObject[this.model.key].includes(item.slug)
                    : this.queryObject[this.model.key] === item.slug
                : false
        },

        setFilter(item) {
            let filter = this.queryObject[this.model.key]
            const routeObject = { query: this.queryObject }

            if (!filter) routeObject.query[this.model.key] = [item.slug]
            else if (Array.isArray(filter)) {
                const idx = filter.indexOf(item.slug)

                if (idx === -1) filter = [...filter, item.slug]
                else filter.splice(idx, 1)

                routeObject.query[this.model.key] = filter
            } else routeObject.query[this.model.key] = filter === item.slug ? undefined : [item.slug]

            this.$router.push(routeObject)

            this.scrollToTop()
        },
    },
}
