
export default {
    props: {
        vendor: {
            type: String,
            default: '',
        },
        // eslint-disable-next-line vue/require-default-prop
        right: null,
        // eslint-disable-next-line vue/require-default-prop
        separator: null,

        buttonText: {
            type: String,
            default: '',
        },
    },

    computed: {
        cover() {
            return this.model.cover && this.model.cover.id ? `${process.env.apiUrl}/api/v1/attachments/${this.model.cover.id}` : null
        },

        price() {
            return parseInt(this.model?.metas?.price_level || '0')
        },

        link() {
            return this.model?.url
        },

        services() {
            return this.model?.taxonomy?.filter((taxonomy) => taxonomy?.content_type.slug === 'services')
        },

        model() {
            return JSON.parse(unescape(this.vendor || {}))
        },
    },
}
