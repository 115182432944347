import { render, staticRenderFns } from "./ListHero.vue?vue&type=template&id=ddeb4a66&scoped=true&lang=pug"
import script from "./ListHero.vue?vue&type=script&lang=js"
export * from "./ListHero.vue?vue&type=script&lang=js"
import style0 from "./ListHero.vue?vue&type=style&index=0&id=ddeb4a66&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddeb4a66",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyInput: require('/app/components/inputs/Input.vue').default})
