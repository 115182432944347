
import { mapGetters } from 'vuex'
import promotions from './idc-promotions'

export default {
    mixins: [promotions],

    props: {
        isCard: Boolean,

        promotionKey: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isViewed: false,
        }
    },

    computed: {
        ...mapGetters({ isPremiumUser: 'user/isPremiumUser' }),

        viewEvent() {
            return this.model.viewEvent
                ? {
                      method: 'Google',
                      event_category: this.model.viewEvent.eventCategory,
                      event_action: this.model.viewEvent.eventAction,
                      event_label: this.model.viewEvent.eventLabel,
                      value: this.model.viewEvent.eventValue,
                  }
                : {}
        },
    },

    methods: {
        handleIntersect(isIntersected) {
            if (!this.isViewed && !isIntersected) {
                this.isViewed = true
            }

            if (this.isViewed) this.$nuxt.$emit('idc-sticky-promotion', isIntersected)
        },
    },
}
