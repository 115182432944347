import { render, staticRenderFns } from "./FilterBar.vue?vue&type=template&id=9768c850&scoped=true&lang=pug"
import script from "./FilterBar.vue?vue&type=script&lang=js"
export * from "./FilterBar.vue?vue&type=script&lang=js"
import style0 from "./FilterBar.vue?vue&type=style&index=0&id=9768c850&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9768c850",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyFilter: require('/app/components/list/Filter.vue').default})
