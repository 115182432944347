
import hasModel from '@/mixins/hasModel'
import hasPageMeta from '@/mixins/hasPageMeta'

export default {
    name: 'DefaultPage',

    mixins: [hasModel, hasPageMeta],

    computed: {
        taxonomy() {
            return this.model.taxonomy || []
        },

        tags() {
            return this.model.tags || []
        },

        categories() {
            return this.taxonomy.filter((el) => !el.is_hidden && el.content_type && el.content_type.slug === 'categories')
        },

        cover() {
            return this.model.cover && this.model.cover.id ? `${process.env.apiUrl}/api/v1/attachments/${this.model.cover.id}` : null
        },

        title() {
            return this.model.name || undefined
        },

        template() {
            if (this.model.metas && this.model.metas.template) {
                return this.model.metas.template || 'default'
            }
            return 'default'
        },

        contentClass() {
            switch (this.template) {
                case 'landing-page':
                    return { 'landing-page': true }
                case 'planning':
                    return { 'planning-landing-page': true }
                default:
                    return 'template-default'
            }
        },

        metas() {
            return this.model.metas || {}
        },

        containerClass() {
            if (!this.metas?.hero_image_container) return 'container-md'
            return this.metas.hero_image_container === 'full' ? 'w-full' : this.metas.hero_image_container
        },

        altText() {
            return this.metas.hero_image_alt || this.model.name
        },

        siteTitle() {
            return 'Loverly - ' + this.model?.name
        },

        siteDescription() {
            return this.model?.excerpt
        },

        previewImage() {
            return this.model?.cover?.url ? this.model.cover.url : `${process.env.baseUrl}/icon.png`
        },
    },
}
