import { render, staticRenderFns } from "./NoContent.vue?vue&type=template&id=2a68364f&lang=pug"
import script from "./NoContent.vue?vue&type=script&lang=js"
export * from "./NoContent.vue?vue&type=script&lang=js"
import style0 from "./NoContent.vue?vue&type=style&index=0&id=2a68364f&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyBoardItem: require('/app/components/elements/user/BoardItem.vue').default,LoverlyArticleItem: require('/app/components/pages/articles/ArticleItem.vue').default,LoverlyRealWeddingItem: require('/app/components/pages/real-weddings/RealWeddingItem.vue').default,LoverlyVendorItem: require('/app/components/pages/vendors/VendorItem.vue').default,LoverlyInspirationItem: require('/app/components/pages/wedding-inspiration/InspirationItem.vue').default,LoverlyCarousel: require('/app/components/elements/containers/Carousel.vue').default})
