import { render, staticRenderFns } from "./ArticleListPage.vue?vue&type=template&id=77441a0d&lang=pug"
import script from "./ArticleListPage.vue?vue&type=script&lang=js"
export * from "./ArticleListPage.vue?vue&type=script&lang=js"
import style0 from "./ArticleListPage.vue?vue&type=style&index=0&id=77441a0d&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyArticleFilter: require('/app/components/pages/articles/ArticleFilter.vue').default,LoverlyArticleList: require('/app/components/pages/articles/ArticleList.vue').default,LoverlyCategoryList: require('/app/components/pages/articles/CategoryList.vue').default})
