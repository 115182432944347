
import isLoading from '@/mixins/isLoading'

export default {
    mixins: [isLoading],

    props: {
        colors: {
            type: Array,
            default: () => {
                return []
            },
        },

        currentColor: {
            type: null,
            default: null,
        },

        activeColorSize: {
            type: Number,
            default: 0.8,
        },

        modal: Boolean,

        checkProp: {
            type: String,
            default: 'slug',
        },
    },

    methods: {
        isActive(color) {
            if (!this.currentColor || (Array.isArray(this.currentColor) && !this.currentColor.length)) return false
            if (Array.isArray(this.currentColor)) {
                return (
                    this.currentColor.findIndex((el) => {
                        return el[this.checkProp] === color[this.checkProp]
                    }) !== -1
                )
            } else {
                return this.currentColor[this.checkProp] === color[this.checkProp]
            }
        },
    },
}
