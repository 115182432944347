
import hasModel from '@/mixins/hasModel'
import queryParams from '~/mixins/queryParams'

export default {
    mixins: [hasModel, queryParams],

    props: {
        contentType: {
            type: String,
            default: '',
        },

        perPage: {
            type: Number,
            default: 4,
        },
        params: {
            type: Object,
            default: () => {
                return {}
            },
        },
        similar: Boolean,
        small: Boolean,
    },

    async fetch() {
        await this.fetch()
    },

    data() {
        return {
            loading: true,
            list: [],
            meta: {},
            pageNumber: 1,
        }
    },

    computed: {
        currentPage: {
            get() {
                return this.pageNumber
            },

            set(val) {
                this.pageNumber = val
                this.fetch()
            },
        },
    },

    methods: {
        async fetch() {
            let url = `/api/v1/content/list`
            if (this.similar) url = `/api/v1/content/similar/${this.model.id}`
            this.loading = true
            await this.$axios
                .$get(url, {
                    params: {
                        page: this.currentPage,
                        per_page: this.perPage,
                        content_type: this.contentType,
                        'order-dir': 'desc',
                        'order-by': 'publish_at',
                        exclude_id: this.model.id,
                        ...this.params,
                    },
                })
                .then((res) => {
                    this.$set(this, 'list', this.list.concat(res.data || []))

                    this.meta = res.meta || {}
                    this.$forceUpdate()
                })
                .finally((el) => {
                    this.loading = false
                })
        },
    },
}
