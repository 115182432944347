
import capitalize from '@/mixins/capitalize'

export default {
    mixins: [capitalize],

    props: {
        // eslint-disable-next-line vue/require-default-prop
        value: null,
        removeClass: Boolean,
        options: {
            type: Array,
            default: () => {
                return []
            },
        },
        placeholder: {
            type: String,
            default: '',
        },
        classes: {
            type: String,
            default: '',
        },
        disabled: Boolean,
        // eslint-disable-next-line vue/require-default-prop
        selected: null,
    },

    methods: {
        onKeyUp(e) {
            if (e.keyCode === 13) this.$emit('enter')
        },

        onInput(e) {
            this.$emit('input', e.target.value)
        },

        onFocus(e) {
            this.$emit('focus')
        },
    },
}
