
import { mapState } from 'vuex'
import hasPageMeta from '@/mixins/hasPageMeta'
import hasModel from '~/mixins/hasModel'
import hasContentMeta from '~/mixins/hasContentMeta'

export default {
    name: 'VideoSingle',

    mixins: [hasModel, hasContentMeta, hasPageMeta],

    data() {
        return {
            related: [],
        }
    },

    computed: {
        videoCategories() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'video-categories'))
        },

        videoCategory() {
            return this.videoCategories ? this.videoCategories[0] : null
        },

        videoMeta() {
            if (this.metas.video_content) return JSON.parse(this.metas.video_content)
            return {}
        },

        topLink() {
            if (this.videoCategory)
                return {
                    name: 'tools-videos-categories-taxonomy',
                    label: this.videoCategory.name,
                    to: { name: 'tools-videos-categories-taxonomy', params: { taxonomy: this.videoCategory.slug } },
                }
            return undefined
        },

        metas() {
            // @TODO: Move this to page level
            if (this.model?.metas) {
                const metasClone = JSON.parse(JSON.stringify(this.model.metas))
                const titleParts = [`${this.model.name}`, `${this.videoCategory?.name || ''}`, 'Loverly']
                metasClone.title = titleParts.filter((el) => el !== '').join(' | ')
                return metasClone
            }
            return this.model.metas || {}
        },

        siteTitle() {
            const titleParts = [`${this.model.name}`, `${this.videoCategory?.name || ''}`]
            return 'Loverly - ' + titleParts.filter((el) => el !== '').join(' | ')
        },

        siteDescription() {
            return this.model?.excerpt ? this.model.excerpt.slice(0, 140) : this.globalMeta?.description
        },

        previewImage() {
            return this.model?.cover?.url
                ? this.model.cover.url
                : this.vendorWeddingImages?.length
                ? this.vendorWeddingImages[0].cover?.url
                : this.globalMeta?.image
        },
    },

    mounted() {
        this.fetchRelated()
    },

    methods: {
        async fetchRelated() {
            if (!this.videoCategory?.slug) return
            const response = await this.$axios.$get('/api/v1/content/list', {
                params: {
                    content_type: 'videos',
                    per_page: 10,
                    exclude_id: undefined, // this.model.id,
                    page: 1,
                    'taxonomy_video-categories': this.videoCategory?.slug || undefined,
                },
            })
            this.$set(this, 'related', response.data)
        },
    },
}
