
import { mapState, mapGetters } from 'vuex'

export default {
    props: {
        blocks: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapGetters({ isPremiumUser: 'user/isPremiumUser' }),

        ...mapState({
            user: (state) => state.auth.user || {},
        }),

        items() {
            return this.blocks ? JSON.parse(unescape(this.blocks)) : []
        },

        gridItemsClass() {
            const cols = { 1: 'md:grid-cols-1', 2: 'md:grid-cols-2', 3: 'md:grid-cols-3', 4: 'md:grid-cols-4', 5: 'md:grid-cols-5', 6: 'md:grid-cols-6' }
            return this.items?.length ? cols[this.items.length] : cols['1']
        },
    },
}
