
import hasModel from '@/mixins/hasModel'
import impression from '@/mixins/impression'
import handleRouterLinkClick from '@/mixins/handleRouterLinkClick'
import hasContentMeta from '~/mixins/hasContentMeta'

export default {
    mixins: [hasModel, hasContentMeta, impression, handleRouterLinkClick],

    props: {
        hideSaveButton: Boolean,
    },

    computed: {
        service() {
            return this.services?.length ? this.services[0] : null
        },

        src() {
            if (this.avatar?.url) return this.avatar.url
            return this.model?.cover
                ? `${this.apiUrl}/500/500/${this.model.cover.id}`
                : this.mappedImages?.length
                ? this.mappedImages[0]
                : this.model.perkImage || '/emptyspace_realweddings.png'
        },

        apiUrl() {
            return process.env.apiUrl + '/api/v1/attachments'
        },

        mappedImages() {
            return this.images
                .map((el) => {
                    return el.cover ? `${this.apiUrl}/500/500/${el.cover.id}` : undefined
                })
                .filter((el) => el)
        },

        gaEvent() {
            return {
                name: 'click',
                data: { method: 'Google', event_category: 'engagement', event_label: 'vendors', value: 0 },
            }
        },
    },
}
