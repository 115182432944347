
import { mapMutations, mapState } from 'vuex'
import promotions from './idc-promotions'

export default {
    mixins: [promotions],

    data() {
        return {
            showStickyBanner: false,
        }
    },

    computed: {
        ...mapState({
            isIdcBannerOpen: (state) => state.data.isIdcBannerOpen,
        }),

        model() {
            const item = this.computedPromotions.find((el) => el.id === this.idcPromotions.sticky)
            return item?.id ? item : this.getRandomPromotion()
        },
    },

    mounted() {
        this.$nuxt.$on('idc-sticky-promotion', (el) => {
            this.showStickyBanner = el
        })
    },

    methods: {
        ...mapMutations({
            setIdcBannerOpen: 'data/setIdcBannerOpen',
        }),
    },
}
