
import hasContentMeta from '~/mixins/hasContentMeta'

export default {
    mixins: [hasContentMeta],

    props: {
        realWedding: {
            type: String,
            default: '',
        },

        buttonText: {
            type: String,
            default: 'View Full Gallery',
        },
    },

    computed: {
        cover() {
            return this.model.cover && this.model.cover.id ? `${process.env.apiUrl}/api/v1/attachments/${this.model.cover.id}` : null
        },
        model() {
            return JSON.parse(unescape(this.realWedding || {}))
        },
        link() {
            return '/real-weddings/' + this.model?.slug
        },
    },

    methods: {
        showAll() {
            this.$router.push(this.link)
        },
    },
}
