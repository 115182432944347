
import scrollToListTop from '@/mixins/scrollToListTop'
import queryParams from '~/mixins/queryParams'

export default {
    mixins: [queryParams, scrollToListTop],

    props: {
        title: {
            type: String,
            default: '',
        },

        slug: {
            type: String,
            default: '',
        },

        afterLabel: {
            type: String,
            default: '',
        },
    },

    async fetch() {
        if (this.slug) {
            const response = await this.$axios.$get(
                `${this.queryParams('/api/v1/content/list', {
                    content_type: this.slug,
                    with_children: true,
                    flat_list: true,
                    per_page: 100,
                })}`
            )
            this.taxonomy = response.data
        }
    },

    data() {
        return {
            taxonomy: [],
        }
    },

    methods: {
        getLink(item) {
            const queryObject = {}
            queryObject[this.slug] = item.slug
            return { query: queryObject }
        },
    },
}
