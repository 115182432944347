
import hasModel from '@/mixins/hasModel'
import { mapState } from 'vuex'

export default {
    mixins: [hasModel],

    props: {
        forcedHover: {
            type: Boolean,
            default: false,
        },

        showEditButton: {
            type: Boolean,
        },

        preview: {
            type: Boolean,
        },

        isPosse: Boolean,
        track: Boolean,
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
        }),

        images() {
            if (this.contentType === 'vision-boards') {
                return this.model.taxonomy
                    ? this.model.taxonomy
                          .map((el) => {
                              return el.cover ? el.cover.url : undefined
                          })
                          .filter((el) => el)
                    : []
            } else {
                return this.model.items
                    ? this.model.items
                          .map((el) => {
                              return el.cover ? el.cover.url : undefined
                          })
                          .filter((el) => el)
                    : []
            }
        },

        link() {
            return { name: 'users-username-boards-id', params: { username: this.model.user?.username || this.user.username, id: this.model.slug } }
        },

        contentType() {
            return this.model.content_type?.slug
        },

        itemsLength() {
            return this.contentType === 'vision-boards' ? this.model?.taxonomy?.length : this.model?.items?.length
        },
    },

    watch: {
        model: {
            handler() {
                if (this.model.id && this.track) {
                    this.$trackEvent('IMPRESSION', {
                        content_type: this.model.content_type ? this.model.content_type.slug : `${this.model?.user?.username}-boards`,
                        name: this.model.name,
                        id: this.model.id,
                    })
                }
            },
            immediate: true,
        },
    },

    methods: {
        handleRouterLinkClick() {
            this.$trackEvent('CLICK', {
                name: this.model.name,
                content_type: this.model.content_type ? this.model.content_type.slug : `${this.model?.user?.username}-boards`,
                id: this.model.id,
            })
        },
    },
}
