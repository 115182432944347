
export default {
    props: {
        imageSlug: {
            type: String,
            default: '',
        },

        contentType: {
            type: String,
            default: '',
        },

        discover: {
            type: Boolean,
            default: false,
        },

        altTitle: {
            type: String,
            default: '',
        },

        altText: {
            type: String,
            default: '',
        },
    },

    async fetch() {
        await this.$axios
            .$get(`/api/v1/content/list`, {
                params: {
                    page: 1,
                    content_type: this.contentType,
                    per_page: 3,
                    'order-by': 'created_at',
                    'order-dir': 'desc',
                },
            })
            .then((res) => {
                this.list = res.data
            })
    },

    data() {
        return {
            list: [],
            mobile: undefined,
        }
    },

    computed: {
        contentTypeFormatted() {
            return this.contentType.replace('-', ' ')
        },
    },

    mounted() {
        this.mobile = process.browser ? window.innerWidth < 992 : null
    },

    methods: {
        triggerAction() {
            this.$emit('triggerAction')
        },
    },
}
