
export default {
    props: {
        product: {
            type: String,
            default: '',
        },
        // eslint-disable-next-line vue/require-default-prop
        right: null,
        // eslint-disable-next-line vue/require-default-prop
        separator: null,

        buttonText: {
            type: String,
            default: '',
        },
    },

    computed: {
        cover() {
            return this.model.cover && this.model.cover.id ? `${process.env.apiUrl}/api/v1/attachments/${this.model.cover.id}` : null
        },

        price() {
            return this.model?.metas?.price
        },

        brand() {
            return this.model?.metas?.brand
        },

        link() {
            return this.model?.metas?.affiliate_link
        },

        model() {
            return JSON.parse(unescape(this.product || {}))
        },
    },
}
