import { render, staticRenderFns } from "./AuthorBox.vue?vue&type=template&id=0691e1ed&scoped=true&lang=pug"
import script from "./AuthorBox.vue?vue&type=script&lang=js"
export * from "./AuthorBox.vue?vue&type=script&lang=js"
import style0 from "./AuthorBox.vue?vue&type=style&index=0&id=0691e1ed&prod&scoped=true&lang=postcss"
import style1 from "./AuthorBox.vue?vue&type=style&index=1&id=0691e1ed&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0691e1ed",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyAvatar: require('/app/components/elements/Avatar.vue').default,LoverlyRenderer: require('/app/components/editor/Renderer.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyUserSocialLinks: require('/app/components/elements/user/UserSocialLinks.vue').default})
