
export default {
    props: {
        page: {
            type: null,
            default: 1,
        },
        lastPage: {
            type: null,
            default: 1,
        },
        // eslint-disable-next-line vue/require-default-prop
        dataFetchState: {
            type: null,
        },
        button: Boolean,

        buttonClasses: {
            type: String,
            default: 'btn-secondary px-5 btn-large uppercase',
        },

        buttonLabel: {
            type: String,
            default: 'Load More',
        },
    },

    data() {
        return {
            innerPage: 1,
        }
    },

    computed: {
        hasNextPage() {
            return parseInt(this.lastPage) > parseInt(this.page)
        },

        onCurrentPage() {
            return parseInt(this.page) === parseInt(this.innerPage)
        },

        isLoading() {
            return this.dataFetchState && this.dataFetchState.pending
        },
    },

    watch: {
        $route(val, oldVal) {
            if (val.query.page !== oldVal.query.page && parseInt(val.query.page) === 1) this.innerPage = this.page
        },
    },

    mounted() {
        this.innerPage = this.page
    },

    methods: {
        handleIntersect(evt, button = false) {
            if (!this.isLoading && this.hasNextPage && this.onCurrentPage) {
                if (document.documentElement.scrollTop > 100 || button) {
                    this.innerPage++
                    this.$emit('loadPage', this.innerPage)
                    this.$emit('otherAction')
                }
            }
        },
    },
}
