
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [hasModel],

    props: {
        collection: {
            type: Array,
            default: () => [],
        },

        parentData: {
            type: Object,
            default: () => {},
        },
        showName: Boolean,
        rounded: Boolean,
        showAll: Boolean,
        defaultMaxHeight: {
            type: String,
            default: 'auto',
        },
    },

    data() {
        return {
            minHeight: 'auto',
            maxHeight: 'auto',
        }
    },

    computed: {
        link() {
            if (this.parentData.id && this.parentData.username)
                return {
                    name: 'image-viewer-username-parent-id',
                    params: { id: this.model.id, parent: this.parentData.id, username: this.parentData.username },
                }
            else if (this.parentData.id) return { name: 'image-viewer-parent-id', params: { id: this.model.id, parent: this.parentData.id } }
            else return { name: 'image-viewer-id', params: { id: this.model.id } }
        },
    },

    watch: {
        showAll: {
            handler() {
                this.maxHeight = this.showAll
                    ? 'auto'
                    : process.browser && window.innerWidth > 576
                    ? this.defaultMaxHeight
                        ? this.defaultMaxHeight.replace('px', '')
                        : 'auto'
                    : 'auto'
            },
            immediate: true,
        },
    },

    mounted() {
        if (process.browser) {
            window.addEventListener('resize', this.getItemHeight)
            const rowsInterval = setInterval(() => {
                if (this.$refs?.image) {
                    this.getItemHeight()
                    clearInterval(rowsInterval)
                }
            }, 100)
        }
    },

    destroyed() {
        if (process.browser) window.removeEventListener('resize', this.getItemHeight)
    },

    methods: {
        getItemHeight() {
            if (this.$refs?.image) {
                const imageMinHeight = this.$refs.image.$el.clientWidth * this.model.ratio
                const maxHeight = this.showAll ? imageMinHeight : this.maxHeight

                if (this.showAll) this.minHeight = maxHeight
                else this.minHeight = this.maxHeight === 'auto' ? imageMinHeight : Math.min(imageMinHeight, maxHeight)
            }
        },
    },
}
