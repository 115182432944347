import { render, staticRenderFns } from "./RealWedding.vue?vue&type=template&id=a520a67c&lang=pug"
import script from "./RealWedding.vue?vue&type=script&lang=js"
export * from "./RealWedding.vue?vue&type=script&lang=js"
import style0 from "./RealWedding.vue?vue&type=style&index=0&id=a520a67c&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyImageGrid: require('/app/components/single/ImageGrid.vue').default,LoverlyVendorItemSmall: require('/app/components/pages/vendors/VendorItemSmall.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyVendorItem: require('/app/components/pages/vendors/VendorItem.vue').default,LoverlyCarousel: require('/app/components/elements/containers/Carousel.vue').default,LoverlyColorItem: require('/app/components/elements/ColorItem.vue').default,LoverlySaveButton: require('/app/components/elements/user/SaveButton.vue').default,LoverlyVideoPlayer: require('/app/components/elements/VideoPlayer.vue').default})
