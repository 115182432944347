import { render, staticRenderFns } from "./ArticlePage.vue?vue&type=template&id=778ae69d&scoped=true&lang=pug"
import script from "./ArticlePage.vue?vue&type=script&lang=js"
export * from "./ArticlePage.vue?vue&type=script&lang=js"
import style0 from "./ArticlePage.vue?vue&type=style&index=0&id=778ae69d&prod&scoped=true&lang=postcss"
import style1 from "./ArticlePage.vue?vue&type=style&index=1&id=778ae69d&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778ae69d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyTitleBlock: require('/app/components/elements/single/TitleBlock.vue').default,LoverlyAuthorBox: require('/app/components/pages/articles/AuthorBox.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyIdcPromotion: require('/app/components/elements/idc-promotions/IdcPromotion.vue').default,LoverlyRenderer: require('/app/components/editor/Renderer.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlySaveButton: require('/app/components/elements/user/SaveButton.vue').default,LoverlyShare: require('/app/components/elements/Share.vue').default,LoverlyAvatar: require('/app/components/elements/Avatar.vue').default,LoverlyRelatedContent: require('/app/components/elements/single/RelatedContent.vue').default,LoverlyIdcStickyPromotion: require('/app/components/elements/idc-promotions/IdcStickyPromotion.vue').default})
