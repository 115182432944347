
import { mapState } from 'vuex'
import capitalize from '@/mixins/capitalize'

export default {
    mixins: [capitalize],

    data() {
        return {
            exceptions: ['utm_source', 'utm_medium'],
        }
    },

    computed: {
        ...mapState({
            taxonomies: (state) => state.data.taxonomies || [],
        }),

        currentFilters() {
            return Object.keys(this.$route.query).reduce((acc, key) => {
                if (!this.exceptions.includes(key)) {
                    if (Array.isArray(this.$route.query[key])) {
                        if (this.$route.query[key].length) {
                            this.$route.query[key].map((el) => {
                                const param = {
                                    contentType: key,
                                    slug: el,
                                }
                                acc.push(param)
                            })
                        }
                    } else if (this.$route.query[key]) {
                        const param = {
                            contentType: key,
                            slug: this.$route.query[key],
                        }
                        acc.push(param)
                    }
                }
                return acc
            }, [])
        },

        flatColors() {
            return this.taxonomies.colors?.reduce((acc, el) => {
                acc.push(el)
                if (el.children) el.children.forEach((x) => acc.push(x))
                return acc
            }, [])
        },
    },

    methods: {
        removeFilter(item) {
            const query = { ...this.$route.query }

            if (Array.isArray(query[item.contentType])) {
                const list = [...query[item.contentType]]
                list.splice(list.indexOf(item.slug), 1)
                this.$set(query, item.contentType, list?.length ? list : undefined)
            } else this.$delete(query, item.contentType)

            this.$router.push({ query })
        },

        getName(item) {
            const taxonomy = this.taxonomies[item.contentType] ? this.taxonomies[item.contentType].find((el) => el.slug === item.slug) : {}

            if (taxonomy?.name) return this.capitalize(taxonomy.name)

            if (item?.contentType === 'locations') return item.slug.replaceAll('-', ' ')

            return this.capitalize(item?.slug)
        },

        getColor(item) {
            const color = this.flatColors.find((el) => el.slug === item.slug)
            return color?.slug ? color.metas.color || '#fff' : '#fff'
        },
    },
}
