import { render, staticRenderFns } from "./BoardModal.vue?vue&type=template&id=110f6c9c&lang=pug"
import script from "./BoardModal.vue?vue&type=script&lang=js"
export * from "./BoardModal.vue?vue&type=script&lang=js"
import style0 from "./BoardModal.vue?vue&type=style&index=0&id=110f6c9c&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyInput: require('/app/components/inputs/Input.vue').default,LoverlyInputSimpleSelect: require('/app/components/inputs/InputSimpleSelect.vue').default,LoverlyInputTextarea: require('/app/components/inputs/InputTextarea.vue').default,LoverlyCheckbox: require('/app/components/inputs/Checkbox.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyModal: require('/app/components/elements/Modal.vue').default})
