import { render, staticRenderFns } from "./Vendor.vue?vue&type=template&id=5552a3a9&lang=pug"
import script from "./Vendor.vue?vue&type=script&lang=js"
export * from "./Vendor.vue?vue&type=script&lang=js"
import style0 from "./Vendor.vue?vue&type=style&index=0&id=5552a3a9&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyImageGrid: require('/app/components/single/ImageGrid.vue').default,LoverlyRealWeddingItem: require('/app/components/pages/real-weddings/RealWeddingItem.vue').default,LoverlyCarousel: require('/app/components/elements/containers/Carousel.vue').default,LoverlyRelatedContent: require('/app/components/elements/single/RelatedContent.vue').default,LoverlySaveButton: require('/app/components/elements/user/SaveButton.vue').default,LoverlyListContentRenderer: require('/app/components/list/ListContentRenderer.vue').default})
