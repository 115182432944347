import { render, staticRenderFns } from "./RelatedContent.vue?vue&type=template&id=00205fde&lang=pug"
import script from "./RelatedContent.vue?vue&type=script&lang=js"
export * from "./RelatedContent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyRealWeddingItem: require('/app/components/pages/real-weddings/RealWeddingItem.vue').default,LoverlyCarousel: require('/app/components/elements/containers/Carousel.vue').default,LoverlyVendorItem: require('/app/components/pages/vendors/VendorItem.vue').default,LoverlyBoardItem: require('/app/components/elements/user/BoardItem.vue').default,LoverlyArticleItem: require('/app/components/pages/articles/ArticleItem.vue').default,LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyBoardModal: require('/app/components/modals/BoardModal.vue').default})
