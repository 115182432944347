
import Vue from 'vue'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

export default {
    props: {
        noLink: Boolean,
        tracking: {
            type: Object,
            default: () => {
                return {}
            },
        },
        withLabel: Boolean,
        title: {
            type: String,
            default: '',
        },
        postUrl: {
            type: String,
            default: '',
        },
        siteTitle: {
            type: String,
            default: '',
        },
        alt: Boolean,
        withLine: Boolean,
    },

    computed: {
        url() {
            return process.env.baseUrl + this.$route.path
        },

        customTitle() {
            if (this.siteTitle) return this.siteTitle
            else if (this.title) return this.title
            return ''
        },
    },

    methods: {
        copyLink() {
            this.$copyText(this.url)
            this.$toast.info('Link copied').goAway(3000)
        },
    },
}
