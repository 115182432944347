
import mobileScroll from '@/mixins/mobileScroll'
import getCategoryUrl from '@/components/pages/articles/mixins/getCategoryUrl'
import debounce from '@/mixins/debounce'
import hasModel from '@/mixins/hasModel'

export default {
    mixins: [mobileScroll, getCategoryUrl, hasModel],

    props: {
        categories: {
            type: Array,
            default: () => {
                return []
            },
        },
        contentTypes: {
            type: Array,
            default: () => {
                return []
            },
        },
    },

    data() {
        return {
            showSearch: false,
            search: null,
        }
    },

    watch: {
        search() {
            this.doSearch(this.search)
        },

        $route() {
            this.search = this.$route.query.q
        },
    },

    methods: {
        closeSearch() {
            this.showSearch = false
        },

        doSearch: debounce(function (val) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    q: val || undefined,
                },
            })
        }, 500),
    },
}
