
import { mapState } from 'vuex'

export default {
    props: {
        product: {
            type: String,
            default: '',
        },
        backgroundColor: {
            type: String,
            default: '#EAECF7',
        },
        buttonLabel: {
            type: String,
            default: 'Upgrade Now',
        },
        fineCopy: {
            type: String,
            default: '',
        },
        successButtonLabel: {
            type: String,
            default: 'Discover Now',
        },
        successButtonUrl: {
            type: String,
            default: '',
        },
        sms: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLoading: false,
            cardInput: null,
            zip: '',
            paymentMethod: null,
            success: false,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
        }),
    },

    async mounted() {
        if (!this.$stripe) return
        const stripe = await this.$stripe()
        const elements = stripe.elements()
        this.cardInput = elements.create('card', {
            style: {
                base: {
                    iconColor: '#8c8c8c',
                    color: '#8c8c8c',
                    fontWeight: '600',
                    fontSize: '16px',
                    ':-webkit-autofill': {
                        color: '#8c8c8c',
                    },
                    '::placeholder': {
                        color: '#8c8c8c',
                    },
                },
                invalid: {
                    iconColor: '#e53e3e',
                    color: '#e53e3e',
                },
            },
        })
        this.cardInput.mount(this.$refs.cardEl)
    },

    methods: {
        async pay() {
            if (!this.$stripe) return
            const { paymentMethod, error } = await this.$stripe.createPaymentMethod({ type: 'card', card: this.cardInput })
            if (error) {
                if (error.message) this.$toast.error(error.message).goAway(3000)
                return
            }
            this.isLoading = true
            this.$axios
                .$post(`/api/v1/payments/checkout`, { price_id: this.product, payment_method: paymentMethod.id })
                .then(() => {
                    this.isLoading = false
                    this.success = true
                    this.$toast.success('The transaction was successful').goAway(10000)
                })
                .catch((e) => {
                    this.isLoading = false
                    e.response.json().then((msg) => {
                        this.$toast.error(`The transaction was not successful: ${msg.error}`).goAway(10000)
                    })
                })
        },
    },
}
