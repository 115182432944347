import { render, staticRenderFns } from "./WeddingInspirationList.vue?vue&type=template&id=7ae700c2&lang=pug"
import script from "./WeddingInspirationList.vue?vue&type=script&lang=js"
export * from "./WeddingInspirationList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyListHero: require('/app/components/list/ListHero.vue').default,LoverlyCurrentListFilters: require('/app/components/list/CurrentListFilters.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyFilterBar: require('/app/components/list/FilterBar.vue').default,LoverlyListContentRenderer: require('/app/components/list/ListContentRenderer.vue').default,LoverlyIdcPromotion: require('/app/components/elements/idc-promotions/IdcPromotion.vue').default,LoverlyInspirationItem: require('/app/components/pages/wedding-inspiration/InspirationItem.vue').default,LoverlyGridMasonry: require('/app/components/elements/containers/GridMasonry.vue').default,LoverlyLoadMoreContainer: require('/app/components/elements/containers/LoadMoreContainer.vue').default,LoverlyNoContent: require('/app/components/blocks/NoContent.vue').default,LoverlyListTaxonomy: require('/app/components/list/ListTaxonomy.vue').default,LoverlyMobileFilterModal: require('/app/components/list/MobileFilterModal.vue').default,LoverlyIdcStickyPromotion: require('/app/components/elements/idc-promotions/IdcStickyPromotion.vue').default})
