
import { mapState } from 'vuex'
import hasPageMeta from '@/mixins/hasPageMeta'
import hasModel from '~/mixins/hasModel'
import hasContentMeta from '~/mixins/hasContentMeta'
import outboundClickTrack from '~/mixins/outboundClickTrack'

export default {
    name: 'ProductSingle',

    mixins: [hasModel, hasContentMeta, hasPageMeta, outboundClickTrack],

    async fetch() {
        await this.$store.dispatch('jsonloader/fetchJson', 'product.json')
    },

    data() {
        return {
            selectedImage: 0,
        }
    },

    computed: {
        ...mapState({
            content: (state) => state.jsonloader.data['product.json'] || null,
        }),

        productCategory() {
            if (this.shopCategories?.length) {
                const category = this.shopCategories[0]
                if (category) return category
            }
            return undefined
        },

        topLink() {
            if (this.productCategory)
                return {
                    name: 'shop-taxonomy-taxonomyid-taxonomy',
                    label: this.productCategory.name,
                    to: { name: 'shop-taxonomy-taxonomyid-taxonomy', params: { taxonomyid: 'shop-categories', taxonomy: this.productCategory.slug } },
                }
            return undefined
        },

        capsules() {
            const capsules = [
                {
                    name: 'Shop',
                    to: '/products',
                },
            ]
            if (this.categories?.length) {
                this.categories.map((el) => {
                    capsules.push({
                        name: el.name,
                        to: { path: '/products', query: { category: el.slug } },
                    })
                })
            }

            return capsules
        },

        productImages() {
            return [this.model, ...(this.model?.children || [])].map((x) => x.cover).filter((x) => x)
        },

        metas() {
            if (this.model?.metas) {
                const metasClone = JSON.parse(JSON.stringify(this.model.metas))
                const titleParts = [`${this.model.name}`, `${this.model.metas?.brand || ''}`, `${this.productCategory?.name || ''}`, 'Loverly']
                metasClone.title = titleParts.filter((el) => el !== '').join(' | ')
                return metasClone
            }
            return this.model.metas || {}
        },

        brand() {
            return this.metas?.brand || undefined
        },

        price() {
            return this.metas?.price || undefined
        },

        shopLink() {
            return this.metas?.affiliate_link || undefined
        },

        siteTitle() {
            const titleParts = [`${this.model.name}`, `${this.metas?.brand || ''}`, `${this.productCategory?.name || ''}`]
            return 'Loverly - ' + titleParts.filter((el) => el !== '').join(' | ')
        },

        siteDescription() {
            return this.model?.excerpt ? this.model.excerpt.slice(0, 140) : this.globalMeta?.description
        },

        previewImage() {
            return this.model?.cover?.url ? this.model.cover.url : this.productImages?.length ? this.productImages[0].cover?.url : this.globalMeta?.image
        },

        currentImage() {
            return this.productImages[this.selectedImage] || {}
        },

        brands() {
            return this.vendors || []
        },

        brandVendor() {
            if (this.brands.length) return this.brands[0]
            return null
        },

        ctaText() {
            const customCta = this.metas?.custom_cta || undefined
            if (customCta && customCta !== '') return customCta
            return 'Shop Now'
        },

        priceText() {
            const price = this.price
            if (!price) return ''
            const customPriceText = this.metas?.custom_price || undefined
            if (customPriceText && customPriceText !== '') return `${customPriceText.replace('{price}', price)}`
            return `$${price}`
        },

        shopCategories() {
            return this.taxonomy.filter((el) => this.contentTypeLookup(el, 'shop-categories'))
        },
    },
}
