
import { mapState } from 'vuex'
export default {
    props: {
        model: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),

        routerLink() {
            if (this.model && this.model.content_type && this.model.content_type.id && this.model.slug)
                return { name: 'internal-editor-type-slug', params: { type: this.model.content_type.id, slug: this.model.id } }
            return null
        },

        isAdmin() {
            return !!(this.$auth && this.$auth.loggedIn && this.user && this.user.is_admin)
        },
    },
}
