
import { mapState } from 'vuex'
import hasPageMeta from '@/mixins/hasPageMeta'
import hasModel from '~/mixins/hasModel'
import hasContentMeta from '~/mixins/hasContentMeta'
import outboundClickTrack from '~/mixins/outboundClickTrack'

export default {
    name: 'VendorSingle',

    mixins: [hasModel, hasContentMeta, hasPageMeta, outboundClickTrack],

    async fetch() {
        await this.getAllImages()
        this.$store.dispatch('jsonloader/fetchJson', 'vendor.json')
    },

    data() {
        return {
            weddings: [],
            taxonomyWeddings: [],
            vendorWeddingImages: [],
            weddingsFetched: false,
            vendorWeddingsFetched: false,
            isVendorManagerSession: false,
        }
    },

    computed: {
        ...mapState({
            content: (state) => state.jsonloader.data['vendor.json'] || null,
        }),

        vendorService() {
            if (this.services?.length) {
                const service = this.services[0]
                if (service) {
                    return service
                }
            }
            return undefined
        },

        topLink() {
            if (this.vendorService)
                return {
                    name: 'vendors-services-taxonomy',
                    label: this.vendorService.name,
                    to: { name: 'vendors-services-taxonomy', params: { taxonomy: this.vendorService.slug } },
                }
            return undefined
        },

        vendorLocation() {
            if (this.locations?.length) {
                const location = this.locations[0]
                if (location) {
                    return location
                }
            }
            return undefined
        },

        capsules() {
            let capsules = [
                {
                    name: 'Vendors',
                    to: '/vendors',
                },
            ]
            if (this.locations?.length) {
                this.locations.map((el) => {
                    const location = { to: { path: '/vendors', query: { location: el.slug } } }
                    if (el.metas?.country) {
                        const state = ['USA', 'United States'].includes(el.metas.country) ? el.metas.state : el.metas.country
                        const city = el.metas.city
                        location.name = state && city ? `${state} ${city}` : el.name
                    } else {
                        location.name = el.name
                    }
                    location.name = location.name + ' Vendors'
                    capsules = [...capsules, location]
                })
            }

            if (this.services?.length) {
                this.services.map((el) => {
                    capsules.push({
                        name: el.name,
                        to: { path: '/vendors', query: { service: el.slug } },
                    })
                })
            }

            return capsules
        },

        metas() {
            // @TODO: Move this to page level
            if (this.model?.metas) {
                const metasClone = JSON.parse(JSON.stringify(this.model.metas))
                const titleParts = [`${this.model.name}`, `${this.vendorService?.name || ''}`, `${this.vendorLocation?.name || ''}`, 'Loverly']
                metasClone.title = titleParts.filter((el) => el !== '').join(' | ')
                return metasClone
            }
            return this.model.metas || {}
        },

        siteTitle() {
            const titleParts = [`${this.model.name}`, `${this.vendorService?.name || ''}`, `${this.vendorLocation?.name || ''}`]
            return 'Loverly - ' + titleParts.filter((el) => el !== '').join(' | ')
        },

        siteDescription() {
            return this.model?.excerpt ? this.model.excerpt.slice(0, 140) : this.globalMeta?.description
        },

        previewImage() {
            return this.model?.cover?.url
                ? this.model.cover.url
                : this.vendorWeddingImages?.length
                ? this.vendorWeddingImages[0].cover?.url
                : this.globalMeta?.image
        },

        contactUrl() {
            if (this.metas?.contact_url) {
                return this.metas.contact_url.includes('http://') || this.metas.contact_url.includes('https://')
                    ? this.metas.contact_url
                    : 'https://' + this.metas.contact_url
            }
            return null
        },

        relatedParams() {
            return {
                taxonomy_services: this.services?.length ? this.services[0].slug : null,
            }
        },

        isReadyToSetImages() {
            return this.weddingsFetched && this.vendorWeddingsFetched
        },

        isBrand() {
            return this.metas.is_brand == '1'
        },

        listSectionParams() {
            return {
                exclude_id: this.model.id,
                type: 'list-section',
                title: `More ${this.vendorService.plural_name || this.vendorService.name}`,
                per_page: 8,
                contentType: 'vendors',
                apiQuery: { taxonomy_services: this.vendorService.slug },
                paramsObject: { taxonomy: this.vendorService.slug },
                route: 'vendors-services-taxonomy',
                buttonLabel: this.content?.viewAllLabel || 'View All',
            }
        },

        brandListSectionParams() {
            return {
                type: 'list-section',
                title: `Featured Products`,
                per_page: 8,
                contentType: 'shop',
                apiQuery: { taxonomy_vendors: this.model.slug },
                paramsObject: { taxonomyid: 'vendors', taxonomy: this.model.slug },
                route: 'shop-taxonomy-taxonomyid-taxonomy',
                buttonLabel: this.content?.viewAllLabel || 'View All',
            }
        },
    },

    watch: {
        $route: {
            handler() {
                this.$set(this, 'weddings', [])
                this.$set(this, 'vendorWeddingImages', [])
                this.vendorWeddingsFetched = false
                this.weddingsFetched = false

                this.getAllImages()
            },
        },

        isReadyToSetImages() {
            if (this.isReadyToSetImages) this.setVendorWeddingImages()
        },
    },

    mounted() {
        this.isVendorManagerSession = this.$cookies.get('is-vendor-manager-session') || false
    },

    methods: {
        async getWeddings() {
            await this.$axios
                .$get(`/api/v1/content/list`, {
                    params: {
                        page: 1,
                        per_page: 6,
                        content_type: 'real-weddings',
                        taxonomy_vendors: this.model.slug,
                        'order-by': 'created_at',
                        'order-dir': 'desc',
                    },
                })
                .then((response) => {
                    this.$set(this, 'taxonomyWeddings', response.data)
                })
                .finally(() => {
                    this.weddingsFetched = true
                })
            if (this.realWeddings?.length)
                await this.$axios
                    .$get(`/api/v1/content/list`, {
                        params: {
                            page: 1,
                            per_page: 6,
                            content_type: 'real-weddings',
                            id: this.realWeddings.map((el) => el.id),
                            'order-by': 'created_at',
                            'order-dir': 'desc',
                        },
                    })
                    .then((res) => {
                        let data = res.data
                        data.filter((el) => data.find((x) => x.id === el.id).length === 1)
                        if (data.length > 6) data = data.slice(0, 6)
                        this.$set(this, 'weddings', [...this.weddings, ...data])
                        this.$set(this, 'taxonomyWeddings', [...this.taxonomyWeddings, ...this.weddings])
                    })
                    .finally(() => {
                        this.vendorWeddingsFetched = true
                    })
            else this.vendorWeddingsFetched = true
        },

        setVendorWeddingImages() {
            let images = []
            if (this.images?.length) images = this.images

            if (this.weddings?.length) {
                const imageCountFromWeddings = this.weddings.length >= 5 ? 5 : 8
                this.weddings.map((wedding) => {
                    images = [...images, ...wedding.taxonomy.filter((el) => el.content_type?.slug === 'wedding-inspiration').slice(0, imageCountFromWeddings)]
                    images = [...images, ...wedding.children.filter((el) => el.content_type?.slug === 'wedding-inspiration').slice(0, imageCountFromWeddings)]
                })

                images = images.filter((object, i) => i === images.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(object)))
            }

            this.vendorWeddingImages = images
        },

        async getAllImages() {
            await this.getWeddings()
        },
    },
}
