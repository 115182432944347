
export default {
    props: {
        content: {
            type: Object,
            default: () => {
                return {}
            },
        },
        visible: Boolean,
    },

    data() {
        return {
            listFilters: [],
            beforRoute: null,
        }
    },

    watch: {
        visible() {
            if (this.visible) this.beforRoute = { name: this.$route.name, params: this.$route.params, query: this.$route.query }
        },
    },

    created() {
        this.$set(this, 'listFilters', this.content.filters)
        this.listFilters = this.listFilters.map(
            (el, index) =>
                (el = { ...el, open: this.listFilters.findIndex((el) => el.key === 'styles') > -1 ? el.key === 'styles' : this.listFilters.length === 1 })
        )
    },

    methods: {
        close() {
            if (this.beforRoute) this.$router.push(this.beforRoute)

            this.$nextTick(() => {
                this.$emit('close')
            })
        },

        toggleFilter(index) {
            this.listFilters.map((el, idx) => (el.open = idx === index ? !el.open : false))
        },
    },
}
