
export default {
    props: {
        title: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },
    },
}
