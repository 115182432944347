import { render, staticRenderFns } from "./FullWidthColorBanner.vue?vue&type=template&id=3d51502e&scoped=true&lang=pug"
import script from "./FullWidthColorBanner.vue?vue&type=script&lang=js"
export * from "./FullWidthColorBanner.vue?vue&type=script&lang=js"
import style0 from "./FullWidthColorBanner.vue?vue&type=style&index=0&id=3d51502e&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d51502e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyButton: require('/app/components/inputs/Button.vue').default})
