import { render, staticRenderFns } from "./ImageBlock.vue?vue&type=template&id=16b0a0dd&lang=pug"
import script from "./ImageBlock.vue?vue&type=script&lang=js"
export * from "./ImageBlock.vue?vue&type=script&lang=js"
import style0 from "./ImageBlock.vue?vue&type=style&index=0&id=16b0a0dd&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default})
