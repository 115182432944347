
import { mapState } from 'vuex'
import tools from '@/mixins/tools'

export default {
    mixins: [tools],

    props: {
        labelText: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        guestLead: {
            type: String,
            default: '',
        },
        userLead: {
            type: String,
            default: '',
        },
        guestButtonText: {
            type: String,
            default: '',
        },
        userButtonText: {
            type: String,
            default: '',
        },
        bgImage: {
            type: String,
            default: '',
        },
        mobileBgImage: {
            type: String,
            default: '',
        },
        tool: {
            type: String,
            default: '',
        },
        containerClass: {
            type: String,
            default: 'container-xl',
        },
    },

    data() {
        return {
            modalShow: false,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),

        lead() {
            return this.$auth.loggedIn ? unescape(this.userLead) : unescape(this.guestLead)
        },

        buttonText() {
            return this.$auth.loggedIn ? this.userButtonText : this.guestButtonText
        },

        image() {
            if (process.browser) {
                return window.innerWidth > 576 ? this.bgImage : this.mobileBgImage
            }
            return null
        },
    },

    mounted() {
        // @TODO majd ha lesz setup folyamat akkor automatikus átirányítás
        if (this.tool) {
            const isSetup = false
            if (isSetup) this.navigateToTool(this.tool)
        }
    },

    methods: {
        getStarted() {
            if (this.$auth.loggedIn) {
                this.navigateToTool(this.tool)
            } else {
                this.modalShow = true
                this.$cookies.set('tool', this.tool)
            }
        },
    },
}
